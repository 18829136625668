import { IconPlus } from "@tabler/icons-react";
import dayjs from "dayjs";
import { FormikProvider, FieldArray, FieldArrayRenderProps, useFormik } from "formik";
import React, { forwardRef, useEffect } from "react";
import { head } from "lodash";
import { getReport, IChannelsReportSettings } from "../../api/report";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mantine/dates";
import { Button, Checkbox, CloseButton, Select, Text } from "@mantine/core";
import { DeviceChannelDto, DeviceDto } from "src/features/devices/types";

interface Props {
    devices: DeviceDto[];
    selectedDevices: number[],
    selectDevices: (ids: number[]) =>void;
}

type ItemProps = {
    value: string;
    name: string;
    comment: string;
};

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ value, name, comment, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
        <Text>{name}</Text>
        <Text size="xs" color="dimmed">
            {comment}
        </Text>
    </div>
));

export const ReportForm: React.FC<Props> = ({ devices, selectedDevices, selectDevices }) => {
    const { t } = useTranslation();

    function getDefaultDeviceId() {
        const device = head(devices);
        return device ? device.Id : 0;
    }

    const formik = useFormik<IChannelsReportSettings>({
        initialValues: {
            StartDt: dayjs().add(-2, "day").toDate(),
            EndDt: dayjs().toDate(),
            ReportSettings: [
                {
                    Id: getDefaultDeviceId(),
                    ChannelIds: [],
                },
            ],
        },
        // validationSchema: validationSchema,
        onSubmit: async (values) => {
            const filename = Array.from(values.ReportSettings)
                .slice(0, 3)
                .reduce<string>((acc, device) => {
                    const address = devices.find((x) => x.Id === device.Id)?.Name;
                    if (!address || acc.length + address.length + 1 > 100) {
                        return acc;
                    }
                    // @ts-ignore
                    const cyrillicToTranslit = new CyrillicToTranslit();
                    const translit = cyrillicToTranslit.transform(address, "_");
                    if (acc.length) {
                        return `${acc}_${translit}`;
                    }

                    return translit;
                }, "");

            await getReport(values, `${filename}_${dayjs().format("YYYYMMDDhhmm")}`);
        },
    });

    useEffect(()=>{
        if (!(JSON.stringify(selectedDevices) === JSON.stringify(formik.values.ReportSettings.map(elem=>elem.Id))))
            selectDevices(formik.values.ReportSettings.map(elem=>elem.Id))
    }, [formik.values.ReportSettings])

    // const { getRootProps, getInputLabelProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    //     useAutocomplete({
    //         disableClearable: true,
    //         options: devices,
    //         getOptionLabel: (option) => option.Name,
    //         value: devices.find((x) => x.Id === formik.values.ReportSettings[index].Id),
    //     });

    function toggleChannel(beaconIndex: number, channel: DeviceChannelDto, channelArrayHelpers: FieldArrayRenderProps) {
        const index = formik.values.ReportSettings[beaconIndex].ChannelIds.findIndex((x) => x === channel.Id);

        if (index >= 0) {
            channelArrayHelpers.remove(index);
        } else {
            channelArrayHelpers.push(channel.Id);
        }
    }

    function isChannelChecked(beaconIndex: number, channelId: number) {
        return formik.values.ReportSettings[beaconIndex].ChannelIds.findIndex((x) => x === channelId) >= 0;
    }

    return (
        <FormikProvider value={formik}>
            <form className="flex flex-col flex-1 bg-white overflow-auto" onSubmit={formik.handleSubmit}>
                <div className="p-4 mb-4 flex-1 max-w-3xl">
                    <div className="mb-4 font-medium text-xl">{t("Отчет по данным")}</div>
                    <div className="mb-4 text-base font-medium text-gray-900">{t("Общие настройки")}</div>
                    <div className="flex mb-4">
                        <div className="flex flex-col max-w-xs mr-4">
                            <DatePicker
                                value={formik.values.StartDt}
                                maxDate={formik.values.EndDt}
                                inputFormat="DD.MM.YYYY"
                                onChange={(date) => formik.setFieldValue("StartDt", date)}
                                label={t("Начало интервала")}
                            />
                        </div>
                        <div className="flex flex-col max-w-xs">
                            <DatePicker
                                value={formik.values.EndDt}
                                minDate={formik.values.StartDt}
                                inputFormat="DD.MM.YYYY"
                                onChange={(date) => formik.setFieldValue("EndDt", date)}
                                label={t("Конец интервала")}
                            />
                        </div>
                    </div>
                    <div className="mb-4 text-base font-bold text-gray-900">{t("Устройства")}</div>
                    <FieldArray
                        name="ReportSettings"
                        render={(arrayHelpers) => (
                            <div>
                                {formik.values.ReportSettings.map((device, index) => (
                                    <div key={index} className="mb-4">
                                        <div className="flex flex-col mb-4">
                                            <div className="flex justify-between items-center">
                                                <Text>
                                                    {t("Устройство")} №{index + 1}
                                                </Text>
                                                <CloseButton
                                                    className="mb-1"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                    disabled={!(formik.values.ReportSettings.length > 1)}
                                                    title="Remove"
                                                    size="xl"
                                                    iconSize={20}
                                                />
                                            </div>
                                            <Select
                                                value={formik.values.ReportSettings[index].Id.toString()}
                                                searchable
                                                nothingFound="No options"
                                                data={devices.map((x) => ({
                                                    value: x.Id.toString(),
                                                    label: x.Name,
                                                    name: x.Name,
                                                    comment: x.Comment,
                                                }))}
                                                itemComponent={SelectItem}
                                                onChange={(value) => {
                                                    formik.setFieldValue(`ReportSettings.${index}.Id`, Number(value));
                                                    formik.setFieldValue(`ReportSettings.${index}.ChannelIds`, []);
                                                }}
                                            />
                                        </div>
                                        <div className="flex flex-col mb-4">
                                            <Text>{t("Датчики")}</Text>
                                            <FieldArray
                                                name={`ReportSettings.${index}.ChannelIds`}
                                                render={(channelArrayHelpers) => (
                                                    <div>
                                                        {devices
                                                            .find(
                                                                (x) => x.Id === formik.values.ReportSettings[index].Id
                                                            )
                                                            ?.DeviceChannels?.map((channel) => (
                                                                <div
                                                                    key={channel.Id}
                                                                    className="p-2 mb-2 flex items-center rounded cursor-pointer hover:bg-gray-100 transition-colors"
                                                                    onClick={() =>
                                                                        toggleChannel(
                                                                            index,
                                                                            channel,
                                                                            channelArrayHelpers
                                                                        )
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        checked={isChannelChecked(index, channel.Id)}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            toggleChannel(
                                                                                index,
                                                                                channel,
                                                                                channelArrayHelpers
                                                                            );
                                                                        }}
                                                                        className="mr-4"
                                                                    />
                                                                    <div className="flex flex-col">
                                                                        <div className="font-normal">
                                                                            {t(channel.Name)}
                                                                        </div>
                                                                        <div className="font-normal text-xs text-gray-700">
                                                                            {t("Последняя метка времени")}:
                                                                            {channel.Timestamp
                                                                                ? dayjs(channel.Timestamp).format(
                                                                                    "DD.MM.YYYY HH:mm:ss"
                                                                                )
                                                                                : "-"}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                ))}
                                <div className="flex mt-8 justify-between">
                                    <Button
                                        onClick={() => arrayHelpers.push({ Id: getDefaultDeviceId(), ChannelIds: [] })}
                                    >
                                        {t("Добавить устройство")} <IconPlus className="ml-2" />
                                    </Button>
                                    <Button type="submit">{t("Скачать отчет")}</Button>
                                </div>
                            </div>
                        )}
                    />
                </div>
            </form>
        </FormikProvider>
    );
};

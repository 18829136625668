import { useState, useEffect } from "react"
import { useRecoverPassword } from "../api/recoverPassword"
import { useTranslation } from "react-i18next"
import { TextInput, Stack, Button, Center } from "@mantine/core"
import { useNavigate } from "react-router"

import { Page } from "src/components/layout/Page"

const PasswordRepairForm: React.FC = () => {

    const recover_pass_mutation = useRecoverPassword()
    const { t } = useTranslation()
    
    const [email, setEmail] = useState('')
    const [EmailInvalid, setEmailInvalid] = useState(true)
    
    const regexpEmail = RegExp("^[\\p{L}\\._]+@[\\p{L}]+\\.[A-Za-z]+$", 'u')
    useEffect(()=>{
        setEmailInvalid(!regexpEmail.test(email) || email.length === 0)
    }, [email])
    return(
        <Center style={{width: "30%", height: "80%", margin: "auto"}}>
            <Page 
                header={{
                    title: t("Восстановление пароля"),
                }}
                style={{overflow: "hidden"}}
            >
                <Stack>
                    <TextInput 
                        label={"e-mail"}
                        placeholder={"example@xmpl.ex"}
                        value={email}
                        onChange={event=>setEmail(event.currentTarget.value)}
                        error={EmailInvalid && email.length > 0 ? t("Введите валидный e-mail") : false}
                        withAsterisk
                    />
                    <Button style={{width: "fit-content", margin: "auto"}} onClick={()=>recover_pass_mutation.mutateAsync({Email: email})}>
                        {t("Отправить письмо для восстановления пароля")}
                    </Button>
                </Stack>
            </Page>
        </Center>
    )
}

export default PasswordRepairForm
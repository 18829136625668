import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

import { RoleEnum } from "src/features/auth";

export type EditUserCompanyCommand = {
    CompanyId:      number,
    UserId:         number,
    NewCompanyId:   number,
};

export const editUserCompany = (command: EditUserCompanyCommand): Promise<EntityId<number>> => {
    return axios.put(`/Companies/user/`, command);
};


export const useEditUserCompany = (company_id: number | null) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("users"+(company_id && company_id>-1 ? company_id : "root"));
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка изменения компании пользователя"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("users"+(company_id && company_id>-1 ? company_id : "root"));
            showNotification({
                title: t("Успех!"),
                message: t("Компания пользователя успешно изменена"),
                autoClose: 5000,
                color: "teal",
            });
        },
        mutationFn: editUserCompany,
    });
};
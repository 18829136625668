import { xor } from "lodash";
import create from "zustand";

import { Tower_full, Tower_small } from "../types";

export type TowersStore = {
    selectedOPLid?:      number | null,
    selectedTowerId?:    number;
    selectedChannelIds?: number[];
    selectTower: (tower: Tower_small) => void;
    unselectTower: () => void;
    // toggleChannel: (deviceChannel: DeviceChannelDto) => void;
};

export const useTowersStore = create<TowersStore>((set) => ({
    selectTower: (tower: Tower_small) => {
        set((state) => ({
            ...state,
            selectedOPLid:      tower.OverheadPowerLineId,
            selectedTowerId:    tower.Id,
            // selectedChannelIds: tower.DeviceChannels.map((x) => x.Id),
        }));
    },
    unselectTower: () => {
        set((state) => ({
            ...state,
            selectedOPLid:      undefined,
            selectedId:         undefined,
            // selectedChannelIds: undefined,
        }));
    },
    // toggleChannel: (deviceChannel: DeviceChannelDto) => {
    //     set((state) => ({
    //         ...state,
    //         selectedChannelIds: xor(state.selectedChannelIds || [], [deviceChannel.Id]),
    //     }));
    // },
}));

import ChartWrapper from "src/components/Monitoring/Chart"
import { useChartData } from "src/features/devices/api/getChartData";

type ChartProps = {
    deviceId: number,
    averaging: number,
    startDt: Date | null | undefined,
    endDt: Date | null | undefined,
    channelsIds: number[],
    label?: React.ReactNode,
}

export const Chart: React.FC<ChartProps> = ({deviceId, averaging, startDt, endDt, channelsIds, label}) => {

    const { data: chartData } = useChartData({
        data: {
            deviceId: deviceId,
            averaging: averaging !== 0 ? Number(averaging) : null,
            startDt: startDt?.toISOString(),
            endDt: endDt?.toISOString(),
        },
    });
    
    return(<>
        {label}
        {chartData ?
        <ChartWrapper
            selectedChannels={channelsIds || []}
            chartData={chartData}
            height={300}
        />
        : <></>}
    </>)
}
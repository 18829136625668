import { useRoutes } from "react-router-dom";

import { useAuth } from "src/lib/auth";

import { protectedRoutes, unregisteredRoutes } from "./protected";
import { publicRoutes } from "./public";
import { UserStateEnum } from "src/features/auth";

export const AppRoutes = () => {
    const auth = useAuth();

    const routes = auth.user ? auth.user.UserState !== UserStateEnum.Invited ? protectedRoutes : unregisteredRoutes : publicRoutes;

    const element = useRoutes(routes);

    return <>{element}</>;
};

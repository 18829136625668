import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type editCompanyCommand = {
    Id:                 number,
    Name:               string,
    CompanyParentId:    number,
};

export const editCompany = (command: editCompanyCommand): Promise<EntityId<number>> => {
    return axios.put(`/Companies`, command);
};


export const useEditCompany = (id: number | null) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("companies"+(id && id>-1 ? id : "root"));
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка изменения компании"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("companies"+(id && id>-1 ? id : "root"));
            showNotification({
                title: t("Успех!"),
                message: t("Компания успешно изменена"),
                autoClose: 5000,
                color: "teal",
            });
        },
        mutationFn: editCompany,
    });
};
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type CreateGroupCommand = {
    Name:            string;
    CompanyParentId: number | null;
};

export const createGroup = (command: CreateGroupCommand): Promise<EntityId<number>> => {
    return axios.post(`/Companies`, command);
};


export const useCreateGroup = (id: number | null) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("companies"+(id && id>-1 ? id : "root"));
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка создания группы"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("companies"+(id && id>-1 ? id : "root"));
            showNotification({
                title: t("Успех!"),
                message: t("Группа успешно создана"),
                autoClose: 5000,
                color: "teal",
            });
        },
        mutationFn: createGroup,
    });
};
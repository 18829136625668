import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQueries, useQuery, UseQueryOptions } from "react-query";
import { ChartData } from "src/features/devices/types";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";

export type ChartDataQuery = {
    deviceId: number;
    averaging?: number | null;
    startDt?: Date | string;
    endDt?: Date | string;
};

export type ChartDataQuerySeveral = {
    devicesIds: number[];
    averaging?: number | null;
    startDt?: Date | string;
    endDt?: Date | string;
};

export const getChartData = (data: ChartDataQuery): Promise<ChartData> => {
    return axios.get(`/devices.getChartData/${data.deviceId}`, {
        params: {
            startDt: data.startDt,
            endDt: data.endDt,
            averaging: data.averaging,
        },
    });
};

export const getChartDataSeveral = (data: ChartDataQuerySeveral): Promise<ChartData>[] => {
    return(
        data.devicesIds.map(id=>
            axios.get(`/devices.getChartData/${id}`, {
                params: {
                    startDt: data.startDt,
                    endDt: data.endDt,
                    averaging: data.averaging,
                },
            })
        )
    )
};

type QueryFnType = typeof getChartData;

type UseChartDataOptions = {
    config?: QueryConfig<QueryFnType>;
    data: ChartDataQuery;
};

export const useChartData = ({ config, data }: UseChartDataOptions) => {
    const { t } = useTranslation();

    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ["chart-data", data],
        queryFn: () => getChartData(data),
        refetchInterval: 30000,
        staleTime: 30000,
        onError: () => {
            showNotification({
                message: t("Ошибка загрузки данных графиков"),
                title: t("Серверная ошибка"),
            });
        },
        ...config,
    });
};

type QueryFnTypeSeveral = typeof getChartDataSeveral;

type UseChartDataOptionsSeveral = {
    config?: UseQueryOptions<QueryFnType>[];
    data: ChartDataQuerySeveral;
};

export const useChartDataSeveral = ({ config, data }: UseChartDataOptionsSeveral) => {
    const { t } = useTranslation();

    function form_data(d: ChartDataQuerySeveral){
        let tmp = []

        for (let id of d.devicesIds){
            let tmp_data = {
                deviceId: id,
                averaging: data.averaging,
                startDt: data.startDt,
                endDt: data.endDt,
            }
            tmp.push({
                queryKey: ["chart-data", data],
                queryFn: () => getChartData(tmp_data),
                staleTime: 30000,
                onError: () => {
                    showNotification({
                        message: t("Ошибка загрузки данных графиков"),
                        title: t("Серверная ошибка"),
                    });
                },
                ...config,
            })
        }
        return tmp
    }

    return useQueries<ExtractFnReturnType<QueryFnType>[]>(form_data(data));
};
import { useState, useEffect } from "react";
import { TextInput, Button, SegmentedControl } from "@mantine/core"
import { useTranslation } from "react-i18next";
import { useForm, yupResolver } from "@mantine/form";

import { RoleEnum } from "src/features/auth";
import { CompanyT } from "src/features/companies/api/getCompanies";

import { useCreateUser } from "../api/createUser";

interface RegisterUserFormProps {
    setOpen: (value: boolean) => void;
    company: CompanyT | null;
}

type RegisterUserCommand = {
    Email: string,
    CompanyId: number | null,
    RoleId: RoleEnum,
}

const RegisterUserForm: React.FC<RegisterUserFormProps> = ({setOpen, company}) => {

    const { t } = useTranslation();

    const [email, setEmail] = useState('')

    const [EmailInvalid, setEmailInvalid] = useState(true)

    const mutation = useCreateUser(company?.Id || -1)

    const regexpEmail = RegExp("^[\\p{L}\\._]+@[\\p{L}]+\\.[A-Za-z]+$", 'u')
    useEffect(()=>{
        setEmailInvalid(!regexpEmail.test(email) || email.length === 0)
    }, [email])

    function handleClose() {
        setOpen(false);
        form.reset();
    }

    const form = useForm<RegisterUserCommand>({
        initialValues: {
            Email: "",
            CompanyId: company?.Id || 0,
            RoleId: RoleEnum.User,
        }
    });

    return(
        <form
            className="flex flex-col overflow-y-scroll"
            onSubmit={form.onSubmit(async (values) => {
                await mutation.mutateAsync(values);
                handleClose();
            })}
        >
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "9px"
            }}
        >
            <span className="font-semibold" style={{width: "100%", wordBreak: "break-word"}}>
                Компания: {company?.Name}
            </span>
            <TextInput
                value={form.values.Email.toString()}
                onChange={(event) => {
                    form.setFieldValue("Email", event.target.value);
                    setEmail(event.target.value)
                }}
                label={t("e-mail")}
                placeholder={t("Введите e-mail")}
                error={form.values.Email.toString().length>0 && EmailInvalid ? t("Введите валидный e-mail") : false}
                styles={{
                    root: {
                        width: "100%"
                    }
                }}
            />
            <SegmentedControl
                value={form.values.RoleId.toString()}
                data={[{label: t("Пользователь"), value: '2'}, {label: t("Администратор"), value: '1'}]}
                onChange={(value)=>form.setFieldValue("RoleId", value === '1' ? RoleEnum.Admin : RoleEnum.User)}
            />
            <Button
                type="submit"
            >
                {t("Зарегистрировать")}
            </Button>
        </div>
        </form>
    )
}

export default RegisterUserForm
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/lib/auth';
import { Stack, PasswordInput, Button, Popover, Progress } from "@mantine/core"

import { useChangePassword } from '../api/changePassword';

const PasswordForm: React.FC = () => {

    const { t } = useTranslation()

    const mutation = useChangePassword();

    const { user } = useAuth()

    const [currentPassword,   setCurrentPassword  ] = useState('')
    const [firstNewPassword,  setFirstNewPassword ] = useState('')
    const [secondNewPassword, setSecondNewPassword] = useState('')

    const [popoverOpened,     setPopoverOpened    ] = useState(false)

    enum PassValidationEnum {
        invalid = -1,
        weak    = 0,
        medium  = 1,
        strong  = 2,
    }

    const [currentPasswordValid,   setCurrentPasswordValid  ] = useState(false)
    const [firstNewPasswordValid,  setFirstNewPasswordValid ] = useState(PassValidationEnum.weak)
    const [secondNewPasswordValid, setSecondNewPasswordValid] = useState(false)
    
    useEffect(()=>{
        setCurrentPasswordValid(currentPassword.length !== 0)
    }, [currentPassword])

    const ValidatePassword = (pass: string) => {
        let validation_map = {
            long:         false,
            validSymbols: false,
            specSymbols:  false,
            bigSymbols:   false,
            smallSymbols: false,
            numbers:      false,
        }
        let UpperCase   = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        let specSymbols = ".:,;?!@#$%^&*_-+="
        let numbers     = "0123456789"
        let result = []
        const PasswordRegex = RegExp("^[A-Za-z0-9\\.:,;\\?!@#\\$%\\^&\\*_\\-\\+=]+$")
        validation_map.long         = pass.length >= 8
        validation_map.validSymbols = PasswordRegex.test(pass)
        for(let symb of pass){
            if (specSymbols.includes(symb))
                validation_map.specSymbols = true
            if (UpperCase.includes(symb))
                validation_map.bigSymbols = true
            if (UpperCase.toLowerCase().includes(symb))
                validation_map.smallSymbols = true
            if (numbers.includes(symb))
                validation_map.numbers = true
        }
        if (!validation_map.validSymbols)
            return PassValidationEnum.invalid
        if (!validation_map.long || !validation_map.numbers || !validation_map.smallSymbols || !validation_map.bigSymbols)
            return PassValidationEnum.weak
        if (!validation_map.specSymbols)
            return PassValidationEnum.medium
        return PassValidationEnum.strong
    }

    useEffect(()=>{
        setFirstNewPasswordValid(ValidatePassword(firstNewPassword))
    }, [firstNewPassword])

    useEffect(()=>{
        setSecondNewPasswordValid(secondNewPassword === firstNewPassword)
    }, [firstNewPassword, secondNewPassword])
    
    return(
        <Stack>
            <PasswordInput 
                label={t("Текущий пароль")}
                placeholder={t("Введите текущий пароль")}
                value={currentPassword}
                onChange={event=>setCurrentPassword(event.currentTarget.value)}
                withAsterisk
            />
            <Popover opened={popoverOpened} position="top" width="target" transition="pop">
                <Popover.Target>
                    <PasswordInput 
                        label={t("Новый пароль")}
                        placeholder={t("Введите новый пароль")}
                        value={firstNewPassword}
                        onChange={event=>setFirstNewPassword(event.currentTarget.value)}
                        withAsterisk
                        onFocusCapture={()=>setPopoverOpened(true)}
                        onBlurCapture ={()=>setPopoverOpened(false)}
                    />
                </Popover.Target>
                <Popover.Dropdown>
                    <Progress
                        color={firstNewPasswordValid === PassValidationEnum.invalid || firstNewPasswordValid === PassValidationEnum.weak ? "red"
                                : firstNewPasswordValid === PassValidationEnum.medium ? "yellow"
                                : "green"
                        }
                        value={
                            firstNewPasswordValid === PassValidationEnum.invalid ? 1
                            : firstNewPasswordValid === PassValidationEnum.weak    ? 25
                            : firstNewPasswordValid === PassValidationEnum.medium  ? 50
                            : 100
                        }
                        style={{marginBottom: 15}}
                    />
                        {
                            firstNewPasswordValid === PassValidationEnum.invalid ? t("Пароль должен содержать только буквы латинского алфавита, цифры и следующие символы")+": .:,;?!@#$%^&*_-+="
                            : firstNewPasswordValid === PassValidationEnum.weak    ? t("Пароль должен быть не короче 8 символов, а также содержать цифры и буквы латинского алфавита обоих регистров.")
                            : firstNewPasswordValid === PassValidationEnum.medium  ? t("Данный пароль подходит. Для увеличения надёжности добавьте спец. символы.")
                            : t("Данный пароль надёжен.")
                        }
                </Popover.Dropdown>
            </Popover>

            <PasswordInput 
                label={t("Подтверждение нового пароля")}
                placeholder={t("Подтвердите новый пароль")}
                value={secondNewPassword}
                onChange={event=>setSecondNewPassword(event.currentTarget.value)}
                withAsterisk
                error={secondNewPasswordValid ? false : t("Пароли не совпадают")}
            />

            <Button
                styles={{
                    root:{
                        borderRadius: "8px",
                        width: "fit-content",
                        margin: "auto",
                    },
                }}
                disabled={!(currentPasswordValid && 
                            (firstNewPasswordValid === PassValidationEnum.strong || firstNewPasswordValid === PassValidationEnum.medium)
                            && secondNewPasswordValid)}
                onClick={()=>mutation.mutateAsync({
                    UserId:      user?.Id,
                    Password:    currentPassword,
                    NewPassword: firstNewPassword,
                })}
            >
                {t("Сменить пароль")}
            </Button>
        </Stack>
    )
}

export default PasswordForm
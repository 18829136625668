import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { Tower_full } from "../types";

export const getTower = (id: number | undefined): Promise<Tower_full> | undefined => {
    if (id && id > 0)
        return axios.get(`/OverheadPowerLineTower/${id}`);
    else
        return undefined
};

type QueryFnType = typeof getTower;

export const useTower = (id: number | undefined) => {
    const { t } = useTranslation();
    let result = useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [`Tower${id}`],
        queryFn: () => getTower(id),
        refetchInterval: 30000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
            showNotification({
                message: t("Ошибка при загрузке опоры"),
                title: t("Серверная ошибка"),
            });
        },
    });
    
    return result
};

import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type CreateOverheadPowerLineTowerCommand = {
    Name: string | null,
    TowerId: number | null,
    Comment: string | null,
    OverheadPowerLineId: number | null,
    Latitude: number | null,
    Longitude: number | null,
    AutoTimeZoneDetection: boolean,
    AutoTimeZoneSeasonChanging: boolean,
    SummerTimeZoneChanging?: Date | null,
    WinterTimeZoneChanging?: Date | null,
    TimeZone: number | null,
    CompanyId: number,
}

export const createTower = (command: CreateOverheadPowerLineTowerCommand): Promise<EntityId<number>> => {
    return axios.post(`/OverheadPowerLineTower`, command);
};

type UseCreateTowerOptions = {
    config?: MutationConfig<typeof createTower>;
};

export const useCreateTower = ({ config }: UseCreateTowerOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("Towers");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка создания опоры"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("Towers");
            showNotification({
                title: t("Успех!"),
                message: t("Опора успешно создана"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: createTower,
    });
};

import {
    Paper,
    Title,
    Text,
    Button,
    SegmentedControl,
    Tooltip,
    Select,
    Drawer,
    Group,
    ActionIcon,
    Badge,
    Popover,
    Stack,
    Flex,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";
import {
    IconArrowLeft,
    IconArrowsMaximize,
    IconArrowsMinimize,
    IconEdit,
    IconFile,
    IconLink,
    IconSettings,
    IconTrash,
    IconUpload,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import ChannelsTable from "src/features/devices/components/ChannelsTable";
import clsx from "clsx";
import Chart from "src/components/Monitoring/Chart";
import { ShortCircuits } from "src/features/devices/components/ShortCircuits";
import { DatePicker } from "@mantine/dates";
import dayjs from "dayjs";
import { ChannelCard } from "src/features/devices/components/ChannelCard";
import { useDisclosure, useFullscreen, useMediaQuery } from "@mantine/hooks";
import { useDevicesStore } from "src/features/devices/store";
import { DeviceDto, DeviceModelEnum, OscilloscopeTypeEnum, OscilloscopeStateEnum } from "src/features/devices/types";
import { UpdateDeviceDrawer } from "src/features/devices/components/UpdateDeviceDrawer";
import { useChartData } from "../api/getChartData";
import { useDeleteDevice } from "../api/deleteDevice";
import { RemoteControl } from "./RemoteControl";
import { IceV2Calculation } from "./IceV2Calculation";
import { BreakDetection } from "./BreakDetection";
import { useBreakDetectionParameters } from "../api/getBreakDetectionParameters";
import { BindDeviceDrawer } from "./BindDeviceDrawer";
import { FirmwareDeviceDrawer } from "src/features/firmware/components/FirmwareDeviceDrawer";
import { useAuth } from "../../../lib/auth";
import { RoleEnum } from "../../auth";
import { Firmware } from "./Firmware";
import Oscilloscope from "../api/Oscilloscope";
import { useGetOscilloscope } from "../api/getOscilloscope";
import { useOscilloscopes } from "../api/getOscilloscopes";
import { getOscilloscopeFile } from "src/api/oscilloscopeFile";
import { useDeleteOscilloscope } from "../api/deleteOscilloscope";

const Fullscreen = styled.div({
    ...tw`h-full w-full`,
});

type CurrentDevicePanelProps = {
    device: DeviceDto;
};

export const CurrentDevicePanel: React.FC<CurrentDevicePanelProps> = ({ device }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { selectedChannelIds, toggleChannel, unselectDevice } = useDevicesStore();
    const { data: breakDetectionParameters } = useBreakDetectionParameters({ query: { DeviceId: device.Id } });
    const { data: oscilloscopes } = useOscilloscopes({ request: { DeviceId: device.Id } });

    const [selectedOscilloscopeId, setSelectedOscilloscopeId] = useState<string | null>(null);

    useEffect(() => {
        if (!selectedOscilloscopeId && Array.isArray(oscilloscopes) && oscilloscopes.length !== 0) {
            setSelectedOscilloscopeId(
                oscilloscopes?.map((x) => ({
                    label: dayjs(x.MeasurementsTimestamp).format("DD.MM.YY HH:mm:ss"),
                    value: x.Id.toString(),
                }))[0].value || null
            );
        } else setSelectedOscilloscopeId(null);
    }, [oscilloscopes]);

    const selectedOscilloscope = oscilloscopes?.find(
        (oscilloscope) => oscilloscope.Id.toString() === selectedOscilloscopeId
    );

    const setOscilloscopeMutation = useGetOscilloscope();

    function handleGetOscilloscope() {
        setOscilloscopeMutation.mutateAsync({
            DeviceId: device.Id,
        });
    }

    const { ref: fullscreenRef, toggle: toggleFullscreen, fullscreen } = useFullscreen();

    const [startDt, setStartDt] = useState<Date | undefined | null>(dayjs().add(-1, "day").toDate());
    const [endDt, setEndDt] = useState<Date | undefined | null>(dayjs().toDate());
    const [averaging, setAveraging] = useState<number>(0);

    const [tab, setTab] = useState<string | undefined>("sensors");
    useEffect(() => {
        setTab((prevTab): string => {
            return prevTab !== undefined
                ? tabs.map((tab) => tab.value).includes(prevTab)
                    ? prevTab
                    : "sensors"
                : "sensors";
        });
    }, [device]);

    const isMiddle = useMediaQuery("(min-width: 1000px)");

    function handleSelectAveraging(value: string) {
        setAveraging(Number(value));
    }

    function getChartSettings() {
        return (
            <div className="md:space-x-2 md:flex">
                <Select
                    id="veraging-select"
                    value={averaging.toString()}
                    onChange={handleSelectAveraging}
                    label={t("Усреднение").toString()}
                    data={[
                        { value: "0", label: t("Без усреднения") },
                        { value: "5", label: t("5 минут") },
                        { value: "10", label: t("10 минут") },
                        { value: "30", label: t("30 минут") },
                        { value: "60", label: t("1 час") },
                        { value: "120", label: t("2 часа") },
                        { value: "240", label: t("4 часа") },
                    ]}
                />
                <DatePicker
                    value={startDt!}
                    maxDate={endDt!}
                    inputFormat="DD.MM.YYYY"
                    onChange={setStartDt}
                    label={t("От").toString()}
                />
                <DatePicker
                    value={endDt!}
                    minDate={startDt!}
                    inputFormat="DD.MM.YYYY"
                    onChange={setEndDt}
                    label={t("До").toString()}
                />
            </div>
        );
    }

    const { data: chartData } = useChartData({
        data: {
            deviceId: device.Id,
            averaging: averaging !== 0 ? Number(averaging) : null,
            startDt: startDt?.toISOString(),
            endDt: endDt?.toISOString(),
        },
    });

    const [sidePanelVisible, setSidePanelVisible] = useState(false);

    function handleDrawerClose() {
        setSidePanelVisible(false);
    }

    useEffect(() => {
        setSidePanelVisible(true);
    }, [device]);
    const [updateDeviceOpened, updateDeviceHandlers] = useDisclosure(false);
    const [deleteDeviceOpened, deleteDeviceHandlers] = useDisclosure(false);
    const [bindDeviceOpened, bindDeviceHandlers] = useDisclosure(false);
    const [firmwareDeviceOpened, firmwareDeviceHandlers] = useDisclosure(false);

    const deleteDeviceMutation = useDeleteDevice();

    const tabs =
        device.DeviceModel === DeviceModelEnum.RDMShortCircuit
            ? [
                  { label: t("Данные датчиков"), value: "sensors" },
                  { label: t("Короткие замыкания"), value: "shortcircuits" },
                  { label: t("Удаленное управление"), value: "remotecontrol" },
                  { label: t("Прошивка устройства"), value: "firmware" },
              ]
            : device.DeviceModel === DeviceModelEnum.RDMUniversal2 ||
              device.DeviceModel === DeviceModelEnum.RDMUniversal22
            ? [
                  { label: t("Данные датчиков"), value: "sensors" },
                  { label: t("Удаленное управление"), value: "remotecontrol" },
                  { label: t("Расчет гололеда"), value: "icecalculation" },
                  { label: t("Обнаружение обрывов"), value: "breakdetection" },
                  { label: t("Прошивка устройства"), value: "firmware" },
                  { label: t("Осциллограммы"), value: "oscilloscope" },
              ]
            : [
                  { label: t("Данные датчиков"), value: "sensors" },
                  { label: t("Удаленное управление"), value: "remotecontrol" },
                  { label: t("Расчет гололеда"), value: "icecalculation" },
                  { label: t("Обнаружение обрывов"), value: "breakdetection" },
                  { label: t("Прошивка устройства"), value: "firmware" },
              ];

    const deviceModels = {
        [DeviceModelEnum.RDM]: t("МДД"),
        [DeviceModelEnum.RDMShortCircuit]: t("КЗ"),
        [DeviceModelEnum.RDMUniversal]: t("Универсальное"),
        [DeviceModelEnum.RDMUniversal2]: t("Универсальное 2.1"),
        [DeviceModelEnum.RDMUniversal22]: t("Универсальное 2.2"),
    };

    const [deleteOscilloscopeOpened, setDeleteOscilloscopeOpened] = useState(false)
    const deleteOscilloscopeMutation = useDeleteOscilloscope()

    useEffect(()=>{
        if (oscilloscopes && oscilloscopes.length !== 0 && oscilloscopes.filter(x=>x.Id.toString() === selectedOscilloscopeId).length === 0)
            setSelectedOscilloscopeId(Array.from(oscilloscopes)[0].Id.toString())
    }, [selectedOscilloscopeId])

    return (
        <React.Fragment>
            {isMiddle ? (
                <div className="overflow-auto h-full flex-1 p-4 z-50 bg-gray-100">
                    <Paper className="mb-4" p="md" shadow="sm">
                        <Stack spacing="xs">
                            <Group>
                                <Button variant="default" leftIcon={<IconArrowLeft />} onClick={unselectDevice}>
                                    {t("К карте").toString()}
                                </Button>
                            </Group>
                            <Group>
                                <Badge radius="xs" variant="filled">
                                    {deviceModels[device.DeviceModel]}
                                </Badge>
                                <Badge color="gray" radius="xs" variant="outline">
                                    {device.CCID}
                                </Badge>
                                {device.ICCIDModbus64 ? (
                                    <Badge color="gray" radius="xs" variant="outline">
                                        {t("ICCID") + ": " + device.ICCIDModbus64}
                                    </Badge>
                                ) : null}
                                {device.MACModbusHEX ? (
                                    <Badge color="gray" radius="xs" variant="outline">
                                        {t("MAC") + ": " + device.MACModbusHEX}
                                    </Badge>
                                ) : null}
                                {device.VersionModbus ? (
                                    <Badge color="gray" radius="xs" variant="outline">
                                        {t("Версия ПО") + ": " + device.VersionModbus}
                                    </Badge>
                                ) : null}
                                {device.DeviceModel == DeviceModelEnum.RDMUniversal2 ||
                                device.DeviceModel == DeviceModelEnum.RDMUniversal22 ? (
                                    <Badge
                                        color={device.TimeSynchronization ? "green" : "red"}
                                        radius="xs"
                                        variant="outline"
                                    >
                                        {device.TimeSynchronization
                                            ? t("Время синхронизировано")
                                            : t("Время не синхронизировано")}
                                    </Badge>
                                ) : null}
                            </Group>
                            <Group position="apart">
                                <Title order={2}>{device.Name}</Title>
                                <Group>
                                    <Tooltip label={t("Редактировать устройство").toString()} withArrow>
                                        <ActionIcon onClick={updateDeviceHandlers.open} size="lg" variant="default">
                                            <IconEdit size={20} />
                                        </ActionIcon>
                                    </Tooltip>
                                    {user?.Role !== RoleEnum.User && (
                                        <Tooltip label={t("Перепрошить устройство").toString()} withArrow>
                                            <ActionIcon
                                                onClick={firmwareDeviceHandlers.open}
                                                size="lg"
                                                variant="default"
                                            >
                                                <IconUpload size={20} />
                                            </ActionIcon>
                                        </Tooltip>
                                    )}
                                    {user?.Role !== RoleEnum.User && (
                                        <Tooltip label={t("Привязать устройство").toString()} withArrow>
                                            <ActionIcon onClick={bindDeviceHandlers.open} size="lg" variant="default">
                                                <IconLink size={20} />
                                            </ActionIcon>
                                        </Tooltip>
                                    )}
                                    {/*<Tooltip label={t("Редактировать устройство").toString()} withArrow>
                                        <ActionIcon onClick={updateDeviceHandlers.open} size="lg" variant="default">
                                            <IconEdit size={20} />
                                        </ActionIcon>
                                    </Tooltip>*/}
                                    <Popover
                                        opened={deleteDeviceOpened}
                                        onClose={deleteDeviceHandlers.close}
                                        position="bottom-end"
                                        transition="pop-top-right"
                                    >
                                        <Popover.Target>
                                            <Tooltip label={t("Удалить устройство").toString()} withArrow>
                                                <ActionIcon
                                                    onClick={deleteDeviceHandlers.open}
                                                    size="lg"
                                                    variant="outline"
                                                    color="red"
                                                >
                                                    <IconTrash size={20} />
                                                </ActionIcon>
                                            </Tooltip>
                                        </Popover.Target>
                                        <Popover.Dropdown>
                                            <Button
                                                color="red"
                                                variant="filled"
                                                size="sm"
                                                onClick={() => {
                                                    deleteDeviceMutation.mutateAsync(device.Id);
                                                }}
                                            >
                                                {t("Удалить устройство!").toString()}
                                            </Button>
                                        </Popover.Dropdown>
                                    </Popover>
                                </Group>
                            </Group>

                            <Text color="gray" size="md">
                                {device.Comment}
                            </Text>
                        </Stack>

                        <SegmentedControl
                            className="mt-4"
                            size={
                                device.DeviceModel == DeviceModelEnum.RDMUniversal2 ||
                                device.DeviceModel == DeviceModelEnum.RDMUniversal22
                                    ? "xs"
                                    : "sm"
                            }
                            value={tab}
                            onChange={setTab}
                            data={tabs}
                        />
                    </Paper>

                    {tab === "sensors" && (
                        <React.Fragment>
                            <ChannelsTable onChannelClick={toggleChannel} />
                            <Paper style={{ minHeight: "480px" }} className="mb-4" p="md" shadow="sm">
                                <Fullscreen
                                    className={clsx({
                                        "bg-white": fullscreen,
                                    })}
                                    ref={fullscreenRef}
                                >
                                    <div className="flex justify-between">
                                        {getChartSettings()}
                                        <Tooltip label={t("На весь экран").toString()}>
                                            <Button className="ml-2" onClick={toggleFullscreen}>
                                                {fullscreen ? <IconArrowsMinimize /> : <IconArrowsMaximize />}
                                            </Button>
                                        </Tooltip>
                                    </div>
                                    {chartData && (
                                        <div style={{ height: "480px" }} className="w-full">
                                            <Chart
                                                selectedDevice={device.Id}
                                                selectedChannels={selectedChannelIds || []}
                                                chartData={chartData}
                                                height={fullscreen ? window.innerHeight - 100 : 480}
                                            />
                                        </div>
                                    )}
                                    {/* {mode === "map" && devicesState.devices && (
                            <MapContainer>
                                <MapComponent
                                    devices={devicesState.devices}
                                    ymaps={ymaps}
                                />
                            </MapContainer>
                        )} */}
                                </Fullscreen>
                            </Paper>
                        </React.Fragment>
                    )}
                    {tab === "shortcircuits" && device.DeviceModel === DeviceModelEnum.RDMShortCircuit && (
                        <ShortCircuits />
                    )}
                    {tab === "remotecontrol" && <RemoteControl />}
                    {tab === "icecalculation" && <IceV2Calculation />}
                    {tab === "breakdetection" && breakDetectionParameters && (
                        <BreakDetection breakDetectionParameters={breakDetectionParameters} deviceId={device.Id} />
                    )}
                    {tab === "firmware" && <Firmware deviceId={device.Id} />}
                    {tab === "oscilloscope" &&
                        (device.DeviceModel === DeviceModelEnum.RDMUniversal2 ||
                            device.DeviceModel == DeviceModelEnum.RDMUniversal22) && (
                            <React.Fragment>
                                <Paper style={{ minHeight: "680px" }} className="mb-4" p="md" shadow="sm">
                                    <Fullscreen
                                        className={clsx({
                                            "bg-white": fullscreen,
                                        })}
                                        ref={fullscreenRef}
                                    >
                                        <div className="flex justify-between">
                                            <Select
                                                data={
                                                    oscilloscopes?.map((x) => ({
                                                        label: dayjs(x.MeasurementsTimestamp).format(
                                                            "DD.MM.YY HH:mm:ss"
                                                        ),
                                                        value: x.Id.toString(),
                                                    })) || []
                                                }
                                                searchable
                                                nothingFound="No options"
                                                value={selectedOscilloscopeId}
                                                onChange={(value) => setSelectedOscilloscopeId(value)}
                                            />
                                            {
                                                <Button
                                                    onClick={handleGetOscilloscope}
                                                    disabled={device.GetOscilloscope}
                                                >
                                                    {t("Считать осциллограмму с устройства")}
                                                </Button>
                                            }
                                            <Flex align="center" justify="center">
                                            {selectedOscilloscope ? 
                                                    <Popover
                                                        opened={deleteOscilloscopeOpened}
                                                        onClose={()=>{setDeleteOscilloscopeOpened(false)}}
                                                        position="bottom-end"
                                                        transition="pop-top-right"
                                                    >
                                                        <Popover.Target>
                                                            <Tooltip label={t("Удалить осциллограмму")} withArrow>
                                                                <ActionIcon
                                                                    onClick={()=>{setDeleteOscilloscopeOpened(true)}}
                                                                    size="lg"
                                                                    variant="outline"
                                                                    color="red"
                                                                >
                                                                    <IconTrash size={20} />
                                                                </ActionIcon>
                                                            </Tooltip>
                                                        </Popover.Target>
                                                        <Popover.Dropdown>
                                                            <Button
                                                                color="red"
                                                                variant="filled"
                                                                size="sm"
                                                                onClick={() => {
                                                                    deleteOscilloscopeMutation.mutateAsync({DeviceId: device.Id, OscilloscopeId: parseInt(selectedOscilloscopeId ? selectedOscilloscopeId : "-1")})
                                                                    setSelectedOscilloscopeId(Array.from(oscilloscopes?oscilloscopes:[])[0].Id.toString());
                                                                    setDeleteOscilloscopeOpened(false)
                                                                }}
                                                            >
                                                                {t("Удалить осциллограмму!")}
                                                            </Button>
                                                        </Popover.Dropdown>
                                                    </Popover>  
                                                : ''}
                                                {selectedOscilloscope ? (
                                                    <Button
                                                        className="ml-2"
                                                        onClick={() => {
                                                            const fileSettings = {
                                                                DeviceId: device.Id,
                                                                OscilloscopeId: selectedOscilloscope.Id,
                                                            };
                                                            const filename =
                                                                device.Name +
                                                                " " +
                                                                device.CCID +
                                                                " " +
                                                                dayjs(
                                                                    selectedOscilloscope.MeasurementsTimestamp
                                                                ).format("DD.MM.YY HH:mm:ss.SSS") +
                                                                ".cff";

                                                            getOscilloscopeFile({
                                                                OscilloscopeFileSettings: fileSettings,
                                                                Filename: filename,
                                                            });
                                                        }}
                                                    >
                                                        Download as COMTRADE
                                                    </Button>
                                                ) : null}
                                                <Tooltip label={t("На весь экран").toString()}>
                                                    <Button className="ml-2" onClick={toggleFullscreen}>
                                                        {fullscreen ? <IconArrowsMinimize /> : <IconArrowsMaximize />}
                                                    </Button>
                                                </Tooltip>
                                            </Flex>
                                        </div>
                                        {selectedOscilloscope ? (
                                            <div style={{ height: "480px" }} className="w-full">
                                                <Oscilloscope
                                                    oscilloscopeDto={selectedOscilloscope}
                                                    device={device}
                                                    height={fullscreen ? window.innerHeight - 100 : 480}
                                                />
                                            </div>
                                        ) : null}
                                    </Fullscreen>
                                </Paper>
                            </React.Fragment>
                        )}
                </div>
            ) : (
                <Drawer
                    title={device.Name}
                    styles={{
                        drawer: {
                            overflowY: "auto",
                        },
                    }}
                    size="full"
                    onClose={handleDrawerClose}
                    opened={sidePanelVisible}
                >
                    <Paper className="p-6">
                        <Select data={tabs} value={tab} onChange={(value) => value && setTab(value)} />
                    </Paper>
                    {/* <SegmentedControl className="mx-4" value={tab} onChange={setTab} data={tabs} /> */}
                    {tab === "shortcircuits" && <ShortCircuits />}
                    {tab === "sensors" && (
                        <React.Fragment>
                            <Paper className="mb-2 p-6">
                                <Title className="mb-6" order={6}>
                                    {t("Настройки").toString()}
                                </Title>
                                {getChartSettings()}
                            </Paper>
                            {device.DeviceChannels.map((x) => (
                                <ChannelCard
                                    channelChartData={chartData?.ChartChannelData.find((y) => y.Id === x.Id)}
                                    channel={x}
                                />
                            ))}
                        </React.Fragment>
                    )}
                </Drawer>
            )}
            <Drawer
                opened={updateDeviceOpened}
                onClose={updateDeviceHandlers.close}
                title={t("Редактировать устройство").toString()}
                padding="md"
                size="xl"
                position="right"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                <UpdateDeviceDrawer device={device} onComplete={updateDeviceHandlers.close} />
            </Drawer>
            <Drawer
                opened={bindDeviceOpened}
                onClose={bindDeviceHandlers.close}
                title={t("Привязать устройство").toString()}
                padding="md"
                size="xl"
                position="right"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                <BindDeviceDrawer device={device} onComplete={bindDeviceHandlers.close} />
            </Drawer>
            <Drawer
                opened={firmwareDeviceOpened}
                onClose={firmwareDeviceHandlers.close}
                title={t("Перепрошить устройство").toString()}
                padding="md"
                size="xl"
                position="right"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                <FirmwareDeviceDrawer device={device} onComplete={firmwareDeviceHandlers.close} />
            </Drawer>
        </React.Fragment>
    );
};

import { useTranslation } from "react-i18next";

import { Page } from "src/components/layout/Page";
import PasswordForm from './PasswordForm';

type PasswordProps = {};

export const Password: React.FC<PasswordProps> = () => {

    const { t } = useTranslation();

    return(
        <>
            <Page
                header={{
                    title: t("Настройки"),
                    description: t("Пароль"),
                }}
            >
                <div
                    style={{
                        width: "50%"
                    }}
                >
                    <PasswordForm/>
                </div>
            </Page>
        </>
    )
}
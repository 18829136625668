import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

import { OverheadLineTypeEnum, OverheadPowerLineTagEnum, OverheadPowerLineTowerTypeEnum } from "../types";

export type CreateOPLCommand = {
    Name:                           string | null,
    Comment:                        string | null,
    FirstOverheadPowerLineTowerId:  number | null,
    LastOverheadPowerLineTowerId:   number | null,
    OverheadPowerLineTag:           OverheadPowerLineTagEnum,
    OverhedLineType:                OverheadLineTypeEnum,
    ParentOverheadPowerLineId:      number | null,
    CompanyId:                      number,
};

export const createOPL = (command: CreateOPLCommand): Promise<EntityId<number>> => {
    return axios.post(`/OverheadPowerLine`, command);
};

type UseCreateOPLOptions = {
    config?: MutationConfig<typeof createOPL>;
};

export const useCreateOPL = ({ config }: UseCreateOPLOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("OPLs");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка создания ВЛ"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("OPLs");
            showNotification({
                title: t("Успех!"),
                message: t("ВЛ успешно создана"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: createOPL,
    });
};

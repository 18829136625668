import styled from "@emotion/styled"
import { Button, Flex, Input } from "@mantine/core"
import { IconCaretLeft, IconSearch } from "@tabler/icons-react"
import Fuse from "fuse.js"
import { ReactNode, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { OPL_small, Tower_small } from "src/features/OPL/types"
import tw from "twin.macro"

interface CardSelectIndicatorProps {
    $selected: boolean;
    $position: "right" | "left";
}

export const Card = styled.div((props) => ({
    ...tw`cursor-pointer border-b border-gray-200 bg-white transition-colors hover:bg-gray-100 relative`,
    minWidth: "100%",
}));

const CardSelectIndicator = styled.div<CardSelectIndicatorProps>(({ $selected, $position }) => ({
    ...tw`absolute transition`,
    top: "8px",
    bottom: "8px",
    [$position]: 0,
    width: "3px",
    borderRadius: $position === "left" ? "0 3px 3px 0" : "3px 0 0 3px",
    ...($selected ? tw`bg-black` : tw`bg-transparent`),
    transition: "all 250ms ease",
}));

interface DeviceCardProps {
    element: any;
    onClick: (Id: number) => void;
    isSelected: boolean;
    onDoubleClick: (Id: number) => void;
}

const ElementCard: React.FC<DeviceCardProps> = ({ element, onClick, isSelected, onDoubleClick }) => {

    function onCardClick() {
        onClick(element.Id);
    }

    return (
        <Card style={{ width: "100%", boxSizing: "border-box", userSelect: "none" }} className="group py-2 pr-3 pl-4" onClick={onCardClick} onDoubleClick={event=>onDoubleClick(element.Id)}>
            <CardSelectIndicator $selected={isSelected} $position="left" />
            <CardSelectIndicator $selected={isSelected} $position="right" />

            <div className="flex items-center justify-between font-medium text-sm mb-2">
                {element.Name}
            </div>

            <div className="flex justify-between">
                <div className="font-normal text-sm text-gray-500">{element.Comment || "-"}</div>
            </div>
        </Card>
    );
};

type SearchingListProps = {
    elements:   (Tower_small | OPL_small)[],
    label?:     string, 
    styles?:    {},
    id:         number | null | (number | null)[],
    setId:      ((x: number | null) => void) | null,
    levels?:    boolean,
    elements_lvl?: (Tower_small | OPL_small)[][],
    ids?:       (number | null)[],
    setIds?:    ((x: number | null) => void)[]
}

export const SearchingList: React.FC<SearchingListProps> = ({ elements, label, styles, id, setId, levels, elements_lvl, ids, setIds }) => {

    const { t } = useTranslation()
    const [ filter, setFilter ] = useState("")

    const [ lvl, setLvl ] = useState(0)

    const [ fuse, setFuse ] = useState(new Fuse((levels ? (elements_lvl ? elements_lvl[0] : []) : elements) ?? [], {
        keys: ["Name"],
    }))

    const searchRef = useRef(null)
    
    useEffect(()=>{
        if (levels){
            setFilter('')
            fuse.setCollection((levels ? (elements_lvl ? elements_lvl[lvl] : []) : elements) ?? [])
            for (let i in setIds){
                if (Number(i) > lvl && typeof setIds[Number(i)] === 'function')
                    setIds[Number(i)](null)
            }
        }
    }, [lvl, elements_lvl, elements])

    return(
        <Flex
            direction="column"
            bg="white"
            sx={(theme) => ({
                height: "100%",
                overflow: "hidden",
                width: "400px",
                zIndex: 10,
                ...styles
            })}
        >
            {elements ?
                <div className="p-2 mb-2">
                    <div className="font-medium text-xl mb-4" style={label ? {} : {color: "white"}}>{label ? label : "empty"}</div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "9px",
                            width: "100%",
                            boxSizing: "border-box",
                        }}
                    >
                        <Input
                            value={filter}
                            onChange={(e: any) => setFilter(e.target.value)}
                            icon={<IconSearch size={20} />}
                            placeholder={t("Поиск")}
                            style={{width: "100%"}}
                            ref={searchRef}
                        />
                        {levels && lvl > 0 ?
                        <Button
                            variant="default"
                            leftIcon={<IconCaretLeft color="#ADB5BD" fill="#ADB5BD"/>}
                            onClick={()=>setLvl(lvl-1)}
                            styles={{
                                leftIcon:{
                                    margin: 0
                                },
                                root: {
                                    padding: 0,
                                    height: "36px",
                                    width: "36px"
                                }
                            }}
                        />
                        :''}
                    </div>
                </div>
            : ''}

            <div className="w-full overflow-y-auto">
                <div className="h-full">
                    {filter
                        ? fuse
                            .search(filter)
                            .map((results) => (
                                <ElementCard
                                    key={results.item.Id}
                                    isSelected={(levels ? (ids ? ids[lvl] : null) : id) === results.item.Id}
                                    element={results.item}
                                    onClick={()=>{
                                        if ((levels ? (ids ? ids[lvl] : null) : id) !== results.item.Id)
                                            if (typeof setId === 'function') 
                                                setId(results.item.Id); 
                                            else {
                                                if (setIds) 
                                                    setIds[lvl](results.item.Id)
                                            }
                                        else
                                            if (typeof setId === 'function') 
                                                setId(null); 
                                            else {
                                                if (setIds) 
                                                    setIds[lvl](null)
                                            }
                                    }}
                                    onDoubleClick={()=>{
                                        if (typeof setId === 'function') setId(results.item.Id); else {if (setIds) setIds[0](results.item.Id)}
                                        setLvl(elements_lvl?.length && ((lvl + 1) < elements_lvl?.length) ? lvl+1 : lvl)
                                    }}
                                />
                            ))
                        : (levels ? elements_lvl ? elements_lvl[lvl] : [] : elements)?.map((element) => (
                            <ElementCard
                                key={element.Id}
                                isSelected={(levels ? (ids ? ids[lvl] : null) : id) === element.Id}
                                element={element}
                                onClick={()=>{
                                    if ((levels ? (ids ? ids[lvl] : null) : id) !== element.Id)
                                        if (typeof setId === 'function') 
                                            setId(element.Id); 
                                        else {
                                            if (setIds) 
                                                setIds[lvl](element.Id)
                                        }
                                    else
                                        if (typeof setId === 'function') 
                                            setId(null); 
                                        else {
                                            if (setIds) 
                                                setIds[lvl](null)
                                        }
                                }}
                                onDoubleClick={()=>{
                                    if (typeof setId === 'function') setId(element.Id); else {if (setIds) setIds[lvl](element.Id)}
                                    setLvl(elements_lvl?.length && ((lvl + 1) < elements_lvl?.length) ? lvl+1 : lvl)
                                }}
                            />
                        ))}
                </div>
            </div>
        </Flex>
    )
}
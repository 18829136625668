import { createStyles, Title, Text, Button, Container, Group } from "@mantine/core";
import Greyjoy from "./Greyjoy.png";

const useStyles = createStyles((theme) => ({
    root: {
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    wrapper: {
        paddingTop: 80,
        paddingBottom: 80,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    label: {
        textAlign: "center",
        fontWeight: 900,
        fontSize: 220,
        lineHeight: 1,
        marginBottom: theme.spacing.xl * 1.5,
        color: theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2],

        [theme.fn.smallerThan("sm")]: {
            fontSize: 120,
        },
    },

    image: {
        width: 220,
        height: 220,
        marginBottom: theme.spacing.xl * 1.5,
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textAlign: "center",
        fontWeight: 900,
        fontSize: 38,

        [theme.fn.smallerThan("sm")]: {
            fontSize: 32,
        },
    },

    description: {
        maxWidth: 500,
        margin: "auto",
        marginTop: theme.spacing.xl,
        marginBottom: theme.spacing.xl * 1.5,
    },
}));

export function InternalServerError() {
    const { classes } = useStyles();

    const classic = "Something bad just happened...";
    const haha = "Quid est mortuus numquam mori potest";

    const randomNumber = Math.floor(Math.random() * 100) + 1;

    return (
        <Container className={classes.root}>
            <div className={classes.wrapper}>
                {randomNumber === 1 ? (
                    <img src={Greyjoy} alt="Error" className={classes.image} />
                ) : (
                    <div className={classes.label}>500</div>
                )}
                <Title className={classes.title}> {randomNumber === 1 ? haha : classic} </Title>
                <Text color="dimmed" size="lg" align="center" className={classes.description}>
                    Our servers could not handle your request. Don't worry, our development team was already notified.
                    Try refreshing the page.
                </Text>
                <Group position="center">
                    <Button variant="filled" size="md" onClick={() => window.location.reload()}>
                        Refresh the page
                    </Button>
                </Group>
            </div>
        </Container>
    );
}

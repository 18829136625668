import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type deleteUserCommand = {
    "Id":     number,
};

export const deleteUser = (command: deleteUserCommand): Promise<EntityId<number>> => {
    return axios.delete(`/Companies/user/`+command.Id);
};


export const useDeleteUser = (company_id: number | null) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("users"+(company_id && company_id>-1 ? company_id : "root"));
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка удаления пользователя"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("users"+(company_id && company_id>-1 ? company_id : "root"));
            showNotification({
                title: t("Успех!"),
                message: t("Пользователь успешно удалён"),
                autoClose: 5000,
                color: "teal",
            });
        },
        mutationFn: deleteUser,
    });
};
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { OPL_small } from "../types";

export const getOPLs = (): Promise<OPL_small[]> => {
    return axios.get(`/OverheadPowerLine`);
};

type QueryFnType = typeof getOPLs;

export const useOPLs = () => {
    const { t } = useTranslation();
    let result = useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ["OPLs"],
        queryFn: () => getOPLs(),
        refetchInterval: 30000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
            showNotification({
                message: t("Ошибка при загрузке ВЛ"),
                title: t("Серверная ошибка"),
            });
        },
    });
    
    return result
};

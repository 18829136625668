import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type deleteTowerCommand = {
    id: number;
};

export const deleteTower = (command: deleteTowerCommand): Promise<EntityId<number>> => {
    return axios.delete(`/OverheadPowerLineTower/${command.id}`);
};

export const useDeleteTower = () => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("Towers");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка удаления опоры"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("Towers");
            showNotification({
                title: t("Успех!"),
                message: t("Опора успешно удалена"),
                autoClose: 5000,
                color: "teal",
            });
        },
        mutationFn: deleteTower,
    });
};
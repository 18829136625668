import { useTranslation } from "react-i18next";
import { Drawer } from "@mantine/core";

import { CompanyT } from "../api/getCompanies";
import EditCompanyForm from './EditCompanyForm'

type CompanyEditorProps = {
    opened:  boolean,
    close:   ()=>void,
    open:    ()=>void,
    company: CompanyT | null,
}

const CompanyEditor: React.FC<CompanyEditorProps> = ({opened, close, open, company}) => {

    const { t } = useTranslation();

    return (
        <Drawer
            opened={opened}
            onClose={close}
            title={t("Редактирование компании")}
            padding="xl"
            size="xl"
            styles={{
                drawer: {
                    overflowY: "auto",
                },
            }}
        >
            <EditCompanyForm
                setOpen={open}
                company={company}
            />
        </Drawer>
    );
};

export default CompanyEditor
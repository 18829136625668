import { useState } from "react"
import { Button, SegmentedControl, Stack, Modal, Group } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { RoleEnum } from "src/features/auth"
import { UserT } from "../api/getUsers"

import { useEditUserRole } from "../api/EditUserRole"
import { useAuth } from "src/lib/auth"

type EditUserFormProps = {
    user: UserT | null,
    setMovingUser: (user: UserT | null)=>void;
    close: ()=>void;
}

const EditUserForm: React.FC<EditUserFormProps> = ({user, setMovingUser, close}) => {
    const { user: curr_user } = useAuth()
    const editUserRoleMutation = useEditUserRole(user?.CompanyId || -1)
    const [newRole, setNewRole] = useState(user?.Role || -1)
    const [deleteOpened, setDeleteOpened] = useState(false)
    const { t } = useTranslation()
    return(
        <Stack style={{marginTop: "20px", gap: "5px"}}>
            <SegmentedControl
                value={newRole?.toString()}
                data={
                    curr_user?.Role === RoleEnum.Admin ? [
                        {label: t("Администратор"), value: RoleEnum.Admin.toString()},
                        {label: t("Пользователь"),  value: RoleEnum.User.toString()}
                    ]
                    : [
                        {label: t("Суперадминистратор"), value: RoleEnum.SuperAdmin.toString()},
                        {label: t("Администратор"),      value: RoleEnum.Admin.toString()},
                        {label: t("Пользователь"),       value: RoleEnum.User.toString()},
                    ]
                }
                onChange={value=>setNewRole(value === RoleEnum.Admin.toString() ? RoleEnum.Admin : value === RoleEnum.SuperAdmin.toString() ? RoleEnum.SuperAdmin : RoleEnum.User)}
            />
            <Modal
                opened={deleteOpened}
                onClose={()=>setDeleteOpened(false)}
            >
                <div className="font-bold">{t("Внимание!")}</div>
                <div>{t("Вы выдаёте пользователю прова суперадминистратора, будьте осторожны!")}</div>
                <Group style={{marginTop: "5px"}} position="center">
                    <Button
                        onClick={()=>{editUserRoleMutation.mutateAsync({
                            CompanyId:      user?.CompanyId || -1,
                            UserId:         user?.Id || -1,
                            Role:           newRole,
                        }); setDeleteOpened(false)}}
                        color= "red"
                    >
                        {t("Продолжить")}
                    </Button>
                </Group>
            </Modal>
                
            <Button
                onClick={user?.Role !== RoleEnum.SuperAdmin && newRole === RoleEnum.SuperAdmin ?
                    ()=>setDeleteOpened(true)
                    :
                    ()=>editUserRoleMutation.mutateAsync({
                        CompanyId:      user?.CompanyId || -1,
                        UserId:         user?.Id || -1,
                        Role:           newRole,
                    })
                }
            >
                {t("Сохранить")}
            </Button>
            <Button 
                style={{marginTop: "10px"}}
                variant="outline"
                onClick={()=>{setMovingUser(user); close()}}
            >
                {t("Перенести пользователя")}
            </Button>
        </Stack>
    )
}

export default EditUserForm
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type changePasswordCommand = {
    UserId:      number | undefined,
    Password:    string,
    NewPassword: string,
};

export const changePassword = (command: changePasswordCommand): Promise<EntityId<number>> => {
    return axios.post(`/Users/change-password`, command);
};


export const useChangePassword = () => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("userpassword");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка изменения пароля, проверьте правильность введённых данных"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("userpassword");
            showNotification({
                title: t("Успех!"),
                message: t("Пароль успешно изменён"),
                autoClose: 5000,
                color: "teal",
            });
        },
        mutationFn: changePassword,
    });
};
import { Navigate, Route, Routes } from "react-router-dom";

import { Login } from "./Login";
import { Register } from "./Register";
import PasswordRepairForm from "src/features/users_new/components/PasswordRepairForm";

export const AuthRoutes = () => {
    return (
        <Routes>
            {/* <Route path="register" element={<Register />} /> */}
            <Route path="passrepair" element={<PasswordRepairForm/>}/>
            <Route path="login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    );
};

import { Input, Stack, Select, Button, Group } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "src/components/layout/Page";
import { useCurrentUser } from "../../auth";
import { useChangeLanguage } from "../api/changeLanguage";
import { LanguagePicker } from "src/features/language/LanguagePicker";

type LanguageProps = {};

export const Language: React.FC<LanguageProps> = () => {
    const { data: user } = useCurrentUser();
    const { t } = useTranslation();
    const setLanguageMutation = useChangeLanguage();

    const [language, setLanguage] = useState<number>(Number(user?.Language));
    useEffect(() => {
        setLanguage(user?.Language ? Number(user.Language) : 1);
    }, [user]);

    if (!user) {
        return null;
    }

    function handleLanguageChange() {
        setLanguageMutation.mutateAsync({
            UserId: user!.Id,
            LanguageId: Number(language),
        });
    }

    return (
        <>
            <Page
                header={{
                    title: t("Настройки"),
                    description: t("Изменение языка интерфейса"),
                }}
            >
                <Stack>
                    <Input.Wrapper>
                        <Group align="center">
                            {t("Язык по умолчанию")}
                            <Group>
                                <LanguagePicker onSelectLanguage={setLanguage} language={language} />
                                <Button onClick={handleLanguageChange}>{t("Применить")}</Button>
                            </Group>
                        </Group>
                    </Input.Wrapper>
                </Stack>
            </Page>
        </>
    );
};

Language.displayName = "Language";

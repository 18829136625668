import { useTranslation } from "react-i18next";
import { Drawer } from "@mantine/core";

import EditUserForm from "./EditUserForm";

import { UserT } from "../api/getUsers";

// import EditUserForm from './EditUserForm'

type UserEditorProps = {
    opened: boolean;
    close: () => void;
    open: () => void;
    user: UserT | null;
    setMovingUser: (user: UserT | null) => void;
};

const UserEditor: React.FC<UserEditorProps> = ({ opened, close, open, user, setMovingUser }) => {
    const { t } = useTranslation();

    return (
        <Drawer
            opened={opened}
            onClose={close}
            title={t("Редактирование пользователя")}
            padding="xl"
            size="xl"
            styles={{
                drawer: {
                    overflowY: "auto",
                },
            }}
        >
            {user?.FIO ? <div className="font-semibold">{user?.FIO}</div> : null}
            {user?.Email ? <div className="font-semibold">{user?.Email}</div> : null}

            <EditUserForm user={user} setMovingUser={setMovingUser} close={close} />
        </Drawer>
    );
};

export default UserEditor;

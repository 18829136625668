import React, { useRef, useEffect, useState } from "react";
import YandexMap from "src/components/Map/YandexMap";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { useYMaps } from "@pbe/react-yandex-maps";
import ReactDOMServer from "react-dom/server";
import ymaps from "yandex-maps";
import { Group, Box, Paper, Badge } from "@mantine/core";
import { useTranslation } from "react-i18next";

type objectDto = {
    Name: string,
    Comment: String,
    Id: number,
    Latitude: number,
    Longitude: number,
}

type MarkerProps = {
    object: objectDto;
};

export const Marker: React.FC<MarkerProps> = ({ object }) => {
    const { t } = useTranslation();

    return (
        <Group spacing="xs">
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.colors.dark,
                    height: 12,
                    width: 12,
                    color: "#fff",
                    opacity: 1,
                    zIndex: 2,
                    borderRadius: "50%",
                    border: "1px solid #fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                })}
            ></Box>
            <Paper
                shadow="sm"
                px={4}
                // sx={{
                //     borderRadius: "50%",
                //     display: "flex",
                //     alignItems: "center",
                // }}
            >
                <Group>
                    <Badge radius="xs" variant="filled" color="dark">
                        {object.Name.slice(0, 10)}
                    </Badge>
                </Group>
            </Paper>
        </Group>
    );
};

Marker.displayName = "Marker";

class PositionMarker {
    private map: YandexMap;
    private object: objectDto;
    private layer: ymaps.Placemark;
    private ymaps: typeof ymaps;

    constructor(map: YandexMap, object: objectDto, clickHandler: any) {
        this.ymaps = map.ymapsAPI;
        this.map = map;
        this.object = { ...object };
        this.layer = this.createPlacemark(this.object);
        this.layer.events.add("click", (event) => {
            if (clickHandler) {
                const target = (event as ymaps.IEvent).get("target");
                if (target) {
                    const placemark = target as ymaps.Placemark;
                    clickHandler(placemark.properties.getAll());
                }
            }
        });
    }

    public getBounds() {
        return this.layer.geometry?.getBounds();
    }

    public focus() {
        const center = this.layer.geometry?.getCoordinates();
        this.map.setCenter(center);
    }

    public show() {
        if (!this.map.isLayerOnMap(this.layer)) {
            this.map.addLayerToClusterer(this.layer);
        }
    }

    public hide() {
        this.map.removeLayerFromClusterer(this.layer);
    }

    private createLayout(template: any) {
        return this.map.ymapsAPI.templateLayoutFactory.createClass(template);
    }

    public startEditing(updateGeometryCallback: any) {
        this.layer.editor.startEditing();
        updateGeometryCallback();
        if (this.layer.geometry) {
            this.layer.geometry.events.add("change", updateGeometryCallback);
        }
    }

    public stopEditing(updateGeometryCallback: any) {
        this.layer.editor.stopEditing();
        if (this.layer.geometry) {
            this.layer.geometry.events.remove("change", updateGeometryCallback);
        }
    }

    private createPlacemark(object: objectDto) {
        let placemarkColor = "#1070ca";
        const properties = {
            Id: object.Id,
            Name: object.Name,
            needLabel: true,
            IconSize: 24,
            Color: placemarkColor,
            clusterCaption: object.Name,
            balloonContentBody: ReactDOMServer.renderToStaticMarkup(<div>{object.Comment || "-"}</div>),
        };

        const options: ymaps.IPlacemarkOptions = {
            hasBalloon: false,
            iconLayout: "default#imageWithContent",
            iconContentLayout: this.createLayout(ReactDOMServer.renderToStaticMarkup(<Marker object={object} />)),
            iconColor: placemarkColor,
            iconContentSize: [280, 32],
            iconImageHref: "",
            iconImageSize: [32, 32],
            iconImageOffset: [-32 / 2, -32 / 2],
        };

        return new this.ymaps.Placemark([object.Latitude, object.Longitude], properties, options);
    }
}

const MapContainer = styled.div(`
    height: 200px;
    width: 100%;
`);

interface SelectPositionMapProps {
    onClick: (value: [number | null | undefined, number | null | undefined]) => void;
    onChange: (value: [number | null | undefined, number | null | undefined]) => void;
    object: objectDto;
}

const SelectPositionMap: React.FC<SelectPositionMapProps> = ({ onClick, onChange, object }) => {
    const objectsMarker = useRef<PositionMarker | null>(null);
    const ymap = useRef<YandexMap>();
    const ymaps = useYMaps();

    const [ userPosition, setUserPosition ] = useState<number[]>([])    

    const get_user_position = () => {
        navigator.geolocation.getCurrentPosition(position => setUserPosition([position.coords.latitude, position.coords.longitude]))
    }

    useEffect(()=>{
        console.log('effect')
        get_user_position()
    }, [navigator])

    useEffect(() => {
        if (ymaps && ymaps.Map) {
            ymap.current = new YandexMap(ymaps, "select-position-universal-ymap", {
                center: userPosition.length !== 0 ? userPosition : [54.194442, 37.599728],
                zoom: 10,
                maxZoom: 18,
            });

            ymap.current.addEvent("click", (e: any) => {
                const position = e.get("coords");
                onClick(position);
            });
        }

        return () => {
            if (ymap.current) {
                ymap.current.destroy();
            }
        };
    }, [ymaps]);

    function onUpdateGeometry(value: any) {
        onChange(value?.originalEvent?.newCoordinates ?? [object.Latitude, object.Longitude]);
    }

    useEffect(() => {
        objectsMarker.current?.stopEditing(onUpdateGeometry);
        objectsMarker.current?.hide();
        if (ymap.current && object.Latitude && object.Longitude) {
            objectsMarker.current = new PositionMarker(ymap.current, object, () => {});
            objectsMarker.current.show();
            objectsMarker.current.focus();
            objectsMarker.current.startEditing(onUpdateGeometry);
        }

        return () => {
            objectsMarker.current?.stopEditing(onUpdateGeometry);
            objectsMarker.current?.hide();
        };
    }, [ymaps, object.Latitude, object.Longitude, object.Name]);

    return <MapContainer id="select-position-universal-ymap" />;
};

export default SelectPositionMap;

import React, { useState, useEffect } from "react";
import { useAuth } from "src/lib/auth";
import { TextInput, Button, Drawer, Flex } from "@mantine/core"
import { useTranslation } from "react-i18next";
import { IconSearch } from "@tabler/icons-react";

import { RoleEnum } from "src/features/auth";
import CreateGroupForm from "./CreateGroupForm";
import GroupsList from "./GroupsList";

import { useCompanies, CompanyT } from "src/features/companies/api/getCompanies";

interface GroupsI {
    company: CompanyT | null;
    select_company:    (company: CompanyT | null) => void;
}

const Groups: React.FC<GroupsI> = ({company, select_company}) => {
    
    const [createGrouplDrawerOpen, setCreateGrouplDrawerOpen] = useState(false)
    const [filter, setFilter] = useState("")

    const { t } = useTranslation();

    const {data: groups} = useCompanies(company ? company.Id : 0)

    const { user } = useAuth()

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                gap: "9px",
                width: "100%",
                height: "100%"
            }}
        >
            <Flex gap={20}>
                <TextInput
                    value={filter}
                    onChange={event=>setFilter(event.currentTarget.value)}
                    icon={<IconSearch/>}
                    placeholder={t("Поиск")}
                    styles={{
                        input: {
                            fontSize: "20px"
                        },
                        root: {
                            flexGrow: 2
                        }
                    }}
                />
                {user?.Role !== RoleEnum.User ? (
                    <Button
                        variant="default"
                        styles={{
                            leftIcon:{
                                margin: 0
                            },
                            root: {
                                height: "36px",
                                width: "36px",
                                flexGrow: 1,
                                backgroundColor: "#000",
                                color: "white",
                                fontWeight: 600,
                                padding: "5px 10px",
                                "&:hover": {
                                    backgroundColor: "#111",
                                }
                            }
                        }}
                        onClick={()=>setCreateGrouplDrawerOpen(true)}
                    >
                        {t("Добавить группу")}
                    </Button>
                ):''}
                <Drawer
                    opened={createGrouplDrawerOpen}
                    onClose={() => setCreateGrouplDrawerOpen(false)}
                    title={t("Добавить группу")}
                    padding="xl"
                    size="xl"
                    styles={{
                        drawer: {
                            overflowY: "auto",
                        },
                    }}
                >
                    <CreateGroupForm
                        setOpen={setCreateGrouplDrawerOpen}
                        current_company_id = {company?.Id || null}
                    />
                </Drawer>
            </Flex>
            <GroupsList 
                current_company_id = {company?.Id || null}
                filter             = {filter}
                select_company     = {select_company}
            />
        </div>
    );
};

export default Groups
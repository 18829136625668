import { TextInput, Button } from "@mantine/core"
import { useTranslation } from "react-i18next";
import { useForm, yupResolver } from "@mantine/form";

import { useEditCompany, editCompanyCommand } from "../api/editCompany";
import { CompanyT } from "../api/getCompanies";

interface EditCompanyFormProps {
    setOpen: (value: boolean) => void;
    company: CompanyT | null;
}

const EditCompanyForm: React.FC<EditCompanyFormProps> = ({setOpen, company}) => {

    const { t } = useTranslation();

    function handleClose() {
        setOpen(false);
        form.reset();
    }

    const createGroupMutation = useEditCompany(company?.CompanyParentId ? company.CompanyParentId : -1) 

    const form = useForm<editCompanyCommand>({
        initialValues: {
            Name: "",
            Id: company?.Id ? company?.Id : 0,
            CompanyParentId: company?.CompanyParentId ? company?.CompanyParentId : 0
        }
    });

    return(
        <form
            className="flex flex-col overflow-y-scroll"
            onSubmit={form.onSubmit(async (values) => {
            await createGroupMutation.mutateAsync(values);
            handleClose();
        })}
        >
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "9px"
            }}
        >
            <TextInput
                value={form.values.Name.toString()}
                onChange={(event) => {
                    form.setFieldValue("Name", event.target.value);
                }}
                label={t("Название новое название компании")}
                placeholder={company?.Name}
                styles={{
                    root: {
                        width: "100%"
                    }
                }}
            />
            <Button
                type="submit"
            >
                {t("Сохранить")}
            </Button>
        </div>
        </form>
    )
}

export default EditCompanyForm
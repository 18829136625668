import React, { useState, useEffect } from "react";
import { useAuth } from "src/lib/auth";
import { TextInput, Button, Drawer } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { IconSearch } from "@tabler/icons-react";

import RegisterUserForm from "./RegisterUserForm";
import UsersList from "./UsersList";

import { RoleEnum } from "src/features/auth";
import { CompanyT } from "src/features/companies/api/getCompanies";
import { UserT } from "../api/getUsers";

interface UsersI {
    company: CompanyT | null;
    movingUser: UserT | null;
    setMovingUser: (user: UserT | null) => void;
}

const Users: React.FC<UsersI> = ({ company, movingUser, setMovingUser }) => {
    const [registerUserDrawerOpen, setRegisterUserDrawerOpen] = useState(false);
    const [filter, setFilter] = useState("");

    const { t } = useTranslation();

    const { user } = useAuth();

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    boxSizing: "border-box",
                    gap: "9px",
                    width: "100%",
                    height: "100%",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        boxSizing: "border-box",
                        paddingRight: 20,
                        gap: "20px",
                    }}
                >
                    <TextInput
                        value={filter}
                        onChange={(event) => setFilter(event.currentTarget.value)}
                        icon={<IconSearch />}
                        placeholder={t("Поиск")}
                        styles={{
                            input: {
                                fontSize: "20px",
                            },
                            root: {
                                flexGrow: 2,
                            },
                        }}
                    />
                    {company && user?.Role !== RoleEnum.User ? (
                        <Button
                            variant="default"
                            styles={{
                                leftIcon: {
                                    margin: 0,
                                },
                                root: {
                                    height: "36px",
                                    width: "36px",
                                    flexGrow: 1,
                                    backgroundColor: "#000",
                                    color: "white",
                                    fontWeight: 600,
                                    padding: "5px 10px",
                                    "&:hover": {
                                        backgroundColor: "#111",
                                    },
                                },
                            }}
                            onClick={() => {
                                setRegisterUserDrawerOpen(true);
                            }}
                        >
                            {t("Добавить пользователя")}
                        </Button>
                    ) : (
                        ""
                    )}
                </div>

                <UsersList
                    company_id={company?.Id || -1}
                    filter={filter}
                    movingUser={movingUser}
                    setMovingUser={setMovingUser}
                />

                <Drawer
                    opened={registerUserDrawerOpen}
                    onClose={() => setRegisterUserDrawerOpen(false)}
                    title={t("Добавить пользователя")}
                    padding="xl"
                    size="xl"
                    styles={{
                        drawer: {
                            overflowY: "auto",
                        },
                    }}
                >
                    <RegisterUserForm setOpen={(state: boolean) => {}} company={company} />
                </Drawer>
            </div>
        </>
    );
};

export default Users;

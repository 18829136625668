import { Paper } from "@mantine/core"
import { Mock } from "./Mock"
import { Tower_full } from "src/features/OPL/types"
import { ChannelsTable } from "./ChannelsTable"

type CurrentTowerProps = {
    tab:        string,
    tower?:     Tower_full,
}

export const CurrentTower: React.FC<CurrentTowerProps>  = ({ tab, tower }) => {

    const filters = {
        "GIO": [
            "Измеренное значение температуры провода ВЛ",
            "Измеренное значение угла стрелы провеса",
            "Гололед (кг на метр)",
            "Гололед (кг на пролет)",
            "Измеренное значение амплитуды вибрации",
            "Измеренное значение частоты вибрации",
            "Измеренное значение амплитуды пляски",
            "Измеренное значение температуры акселерометра",
        ],
        "KZ": [
            "Длительность периода тока до события",
            "Длительность периода тока в момент события",
            "Длительность периода тока после события",
            "Код события",
            "Значение тока короткого замыкания",
            "Разница периодов тока до КЗ и при КЗ",
        ],
        "GP": [
            "Обнаружение обрыва",
            "Габарит",
        ],
        "WP": [
            "Напряжение на ионисторах",
            "Уровень сигнала GSM",
            "GSM result code",
            "Код причины последней перезагрузки",
            "ICCID",
            "MAC adress",
            "Версия ПО",
        ]
    }

    return(
        <Paper className="mb-4" p="md" shadow="sm" style={{overflowY: "scroll", overflowX: "hidden", marginBottom: 0, boxSizing: "border-box", flexGrow: 1}}>
            {
                tab === "GIO" && tower && (tower.DevicesDtos?.length !== 0) ?
                    <ChannelsTable
                        tower={tower}
                        filter={filters["GIO"]}
                    />
                :
                tab === "KZ" && tower && (tower.DevicesDtos?.length !== 0)?
                    <ChannelsTable
                        tower={tower}
                        filter={filters["KZ"]}
                    />
                :
                tab === "GP" && tower && (tower.DevicesDtos?.length !== 0)?
                    <ChannelsTable
                        tower={tower}
                        filter={filters["GP"]}
                    />
                :
                tab === "ASKDTN" && tower && (tower.DevicesDtos?.length !== 0)?
                    <Mock/>
                :
                tab === "WP" && tower && (tower.DevicesDtos?.length !== 0)?
                    <ChannelsTable
                        tower={tower}
                        filter={filters["WP"]}
                    />
                :
                tab === "Notifications" && tower && (tower.DevicesDtos?.length !== 0)?
                    <Mock/>
                : ''
            }
        </Paper>
    )
}
import TowersMap from "src/components/TowersMap/TowersMap"
import { useTowers } from "src/features/OPL/api/getTowers"

export const MapView: React.FC = () => {

    const { data: towers } = useTowers()

    return(<>
        <TowersMap
            towers={towers}
        />
    </>)
}
import { useNavigate } from "react-router-dom";
import Container from "src/components/auth/Container";
import { LoginForm } from "../components/LoginForm";
import { UserStateEnum } from "../types";

export const Login = () => {
    const navigate = useNavigate();

    return (
        <Container className="background-pattern">
            <LoginForm
                onSuccess={(user_state) => {
                    if (user_state === UserStateEnum.Joined) 
                        navigate("/");
                    else{
                        navigate("/register")}
                }}
            />
        </Container>
    );
};

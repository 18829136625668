import { Button, Text, Popover, ActionIcon, Flex } from "@mantine/core";
import { IconTrash, IconDatabaseOff } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAuth } from "src/lib/auth";
import Fuse from "fuse.js";

import { CompanyT, useCompanies } from "src/features/companies/api/getCompanies";
import { RoleEnum } from "src/features/auth";
import { useDeleteGroup } from "src/features/companies/api/deleteCompany";

interface GroupsListI {
    current_company_id: number | null;
    filter: string;
    select_company: (company: CompanyT | null) => void;
}

interface GroupI {
    group: CompanyT;
    grayed: boolean;
    select_company: (company: CompanyT | null) => void;
}

const Group: React.FC<GroupI> = ({ group, grayed, select_company }) => {
    const [hovered, setHovered] = useState(false);
    const [color, setColor] = useState(grayed ? "#EFEFEF" : "#FFF");

    const { t } = useTranslation();

    const [deleteOpened, setDeleteOpened] = useState(false);

    useEffect(() => {
        if (grayed && hovered) setColor("#EAEAEA");
        if (!grayed && hovered) setColor("#FAFAFA");
        if (grayed && !hovered) setColor("#EFEFEF");
        if (!grayed && !hovered) setColor("#FFFFFF");
    }, [hovered]);

    const deleteGroupMutation = useDeleteGroup(group.CompanyParentId ? group.CompanyParentId : -1);

    const delete_group = async () => {
        if (group.Id) await deleteGroupMutation.mutateAsync({ id: group.Id });
    };

    const { user } = useAuth();

    const select_parent_company = () => {
        if (user?.Role !== RoleEnum.User) select_company(group);
    };

    return (
        <div
            style={{
                userSelect: "none",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
                width: "100%",
                overflowX: "hidden",
                padding: "25px 25px",
                backgroundColor: color,
                cursor: "pointer",
            }}
            onDoubleClick={select_parent_company}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div
                style={{
                    width: "calc(100% - 120px)",
                    wordBreak: "break-all",
                }}
            >
                {group.Name}
            </div>
            {user?.Role !== RoleEnum.User ? (
                <Popover
                    opened={deleteOpened}
                    onClose={() => setDeleteOpened(false)}
                    position="left-start"
                    transition="pop-top-right"
                >
                    <Popover.Target>
                        <ActionIcon
                            onClick={() => {
                                setDeleteOpened(true);
                            }}
                            size="lg"
                            variant="outline"
                            color="red"
                        >
                            <IconTrash size={20} />
                        </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <Button
                            variant={"subtle"}
                            color="red"
                            onClick={() => {
                                delete_group();
                            }}
                        >
                            {t("Удалить группу!").toString()}
                        </Button>
                    </Popover.Dropdown>
                </Popover>
            ) : (
                ""
            )}
        </div>
    );
};

const GroupsList: React.FC<GroupsListI> = ({ current_company_id, filter, select_company }) => {
    const { t } = useTranslation();

    const { data: groups } = useCompanies(current_company_id !== null ? current_company_id : 0);

    const fuse = new Fuse(groups ?? [], {
        keys: ["Name"],
    });

    return (
        <Flex
            direction="column"
            bg="white"
            style={{
                overflow: "hidden",
                width: "100%",
                height: "100%",
                borderRadius: "8px 8px 0 0",
                justifyContent: "space-between",
            }}
            gap={9}
        >
            <div className="w-full overflow-y-auto">
                <div className="h-full">
                    {filter
                        ? fuse
                              .search(filter)
                              .map((group, index) => (
                                  <Group group={group.item} grayed={index % 2 === 0} select_company={select_company} />
                              ))
                        : groups?.map((group, index) => (
                              <Group group={group} grayed={index % 2 === 0} select_company={select_company} />
                          ))}
                    {groups?.length === 0 ? (
                        <div
                            style={{
                                width: "100%",
                                height: "20%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    padding: "40px 60px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "0px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "100%",
                                        backgroundColor: "#E9ECEF",
                                        height: "24px",
                                        width: "24px",
                                        padding: "10px",
                                    }}
                                >
                                    <IconDatabaseOff color="#868E96" />
                                </div>
                                <Text color="#A1A09D">{t("Список групп пуст")}</Text>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </Flex>
    );
};

export default GroupsList;

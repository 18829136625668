import { Center, Stack, Title } from "@mantine/core"
import { IconCrutches } from "@tabler/icons-react"

export const Mock: React.FC = () => {
    return(
        <div className="h-full w-full" style={{paddingTop: "25%"}}>
            <Stack align="center" className="h-full w-full">
                <Title order={2}>
                    This page is not ready yet
                </Title>
                <IconCrutches size={100} style={{transform: "rotate(45deg)"}}/>
            </Stack>
        </div>
    )
}
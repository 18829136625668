import { Group, Switch, Text } from "@mantine/core";
import { IconBrandTelegram, IconDatabase, IconSettings } from "@tabler/icons-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { LinksGroup } from "src/components/Navbar/NavbarLinksGroup";
import { useToggleIEC } from "../../iec/api/toggleIEC";
import { UserDto } from "src/features/auth";

type UserMenuProps = {};

export const UserMenu: React.FC<UserMenuProps> = () => {
    const { t } = useTranslation();

    return (
        <div>
            <LinksGroup
                icon={IconBrandTelegram}
                label={t("Уведомления")}
                links={[{ label: "Telegram", link: "/profile/telegram" }]}
            ></LinksGroup>
            <LinksGroup
                icon={IconDatabase}
                label={t("МЭК-104")}
                links={[{ label: t("Управление"), link: "/profile/iec" }]}
            ></LinksGroup>
            <LinksGroup
                icon={IconSettings}
                label={t("Настройки")}
                links={[
                    { label: t("Информация"), link: "/profile/info" }, 
                    { label: t("Пароль"), link: "/profile/password" }, 
                    { label: t("Язык интерфейса"), link: "/profile/language" }
                ]}
            ></LinksGroup>
        </div>
    );
};

UserMenu.displayName = "UserMenu";

import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { RoleEnum, UserDto } from "src/features/auth";
import { FirmwareDto } from "../types/index";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { useAuth } from "../../../lib/auth";

export const getAllFirmwares = (): Promise<FirmwareDto[]> => {
    return axios.get(`/firmware/all`);
};

type QueryFnType = typeof getAllFirmwares;

type UseAllFirmwaresOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useAllFirmwares = ({ config }: UseAllFirmwaresOptions = {}) => {
    const { t } = useTranslation();
    const { user } = useAuth();

    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ["allFirmwares"],
        queryFn: () => getAllFirmwares(),
        refetchInterval: 30000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
            showNotification({
                message: t("Ошибка при загрузке прошивок"),
                title: t("Серверная ошибка"),
            });
        },
        enabled: user?.Role !== RoleEnum.User,
        ...config,
    });
};

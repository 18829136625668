import { useEffect, useState } from "react"
import { MonitoringTowersHead } from "../features/monitoring_towers/components/MonitoringTowersHead"
import { CurrentTower } from "src/features/monitoring_towers/components/CurrentTower"
import { Mock } from "src/features/monitoring_towers/components/Mock"
import { useOPLs } from "src/features/OPL/api/getOPLs"
import { SearchingList } from "src/features/monitoring_towers/components/SearchingList"
import { useTowers } from "src/features/OPL/api/getTowers"
import { useTranslation } from "react-i18next"
import { useOPL } from "src/features/OPL/api/getOPL"
import { useTower } from "src/features/OPL/api/getTower"
import ListCollapser from "src/components/ListCollapser/ListCollapser"
import { useTowersStore } from "src/features/OPL/store"
import { Button } from "@mantine/core"
import { IconArrowLeft } from "@tabler/icons-react"
import { useNavigate } from "react-router"
import { CreationDrawer } from "src/features/companies/components/OP"
import { useAuth } from "src/lib/auth"
import { useDeleteOPL } from "src/features/OPL/api/deleteOPL"
import { useDeleteTower } from "src/features/OPL/api/deleteTower"

type MonitoringTowersViewProps = {
    storeSelectedOPLid?:     number,
    storeSelectedTowerId?:   number
}

export const MonitoringTowersView: React.FC<MonitoringTowersViewProps> = ({storeSelectedOPLid, storeSelectedTowerId}) => {

    const { t } = useTranslation()

    const [ tab, setTab ] = useState("GIO")

    const { data: OPLs } = useOPLs()

    const { data: Towers } = useTowers()

    const [ selectedOPLid, setSelectedOPLid ] = useState<number | null>(null)

    const [ selectedTowerId, setSelectedTowerId ] = useState<number | null>(null)

    const { data: currentOPL } = useOPL(selectedOPLid ? selectedOPLid : undefined)

    const { data: currentTower } = useTower(selectedTowerId ? selectedTowerId : undefined)

    const selectedTower = useTowersStore((state=>state))

    const { unselectTower } = useTowersStore()

    const navigate = useNavigate()

    const deleteOPMutation = useDeleteOPL()

    const deleteTowerMutation = useDeleteTower()

    useEffect(()=>{
        if (selectedTower.selectedOPLid && selectedTower.selectedTowerId){
            setSelectedOPLid(selectedTower.selectedOPLid)
            setSelectedTowerId(selectedTower.selectedTowerId)
        }
    }, [selectedTower])

    const [ editing, setEditing ] = useState(false)

    const { user } = useAuth();

    const tabs = [
        { label: "Гололёд",                 value: "GIO" },
        { label: "КЗ",                      value: "KZ" },
        { label: "Габарит и обрыв",         value: "GP" },
        { label: "Техническая информация",  value: "WP" },
    ]

    return(
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100vh",
            }}
        >
            <ListCollapser>
                <>
                <div className="h-full">
                    <SearchingList
                        label = {t("Мониторинг")}
                        levels
                        elements={[]}
                        id={null}
                        ids = {[selectedOPLid, selectedTowerId]}
                        setId = {null}
                        elements_lvl={[(OPLs ? OPLs : []), (Towers ? Towers.filter(x=>x.OverheadPowerLineId === selectedOPLid) : [])]}
                        setIds={[setSelectedOPLid, setSelectedTowerId]}
                    />
                </div>
                </>
            </ListCollapser>

            <div
                className="p-4"
                style={{
                    width: "100%",
                    maxHeight: "100%",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    boxSizing: "border-box",
                }}
            >
                <MonitoringTowersHead
                    tab={tab}
                    select_tab={setTab}
                    back_button={
                        <Button variant="default" leftIcon={<IconArrowLeft />} onClick={()=>{unselectTower(); navigate("/map")}}>
                            {t("К карте").toString()}
                        </Button>
                    }
                    names_comments={currentOPL && currentTower ? 
                        [{
                            label:      t("ВЛ"),
                            name:       currentOPL?.Name || '-',
                            comment:    currentOPL?.Comment || '-',
                        },
                        {
                            label:      t("Опора"),
                            name:       currentTower?.Name || '-',
                            comment:    currentTower?.Comment || '-',
                        },]
                        :
                        currentOPL ? 
                        [{
                            label:      t("ВЛ"),
                            name:       currentOPL?.Name || '-',
                            comment:    currentOPL?.Comment || '-',
                        },]
                        : [{
                            label:      t(""),
                            name:       'Опора не выбрана',
                            comment:    'Выберите опору',
                        },]
                    }
                    editing={selectedOPLid ? {
                        state: editing,
                        start: ()=>setEditing(true),
                    } : undefined}
                    deleting={selectedOPLid ? {
                        start: ()=>{
                            if (selectedTowerId){
                                setSelectedTowerId(null)
                                selectedTower.unselectTower()
                                deleteTowerMutation.mutateAsync({id: selectedTowerId})}
                            else{
                                setSelectedOPLid(null)
                                selectedTower.unselectTower()
                                deleteOPMutation.mutateAsync({id: selectedOPLid})
                            }
                        },
                    } : undefined}
                    showTabs={selectedTowerId ? true : false}
                    tabs={tabs}
                />

                <CurrentTower
                    tab={tab}
                    tower={currentTower}
                />

                <CreationDrawer
                    opened={editing}
                    close=      {()=>setEditing(false)}
                    company=    {(currentTower) ? currentTower.CompanyId : 0}
                    editing
                    tab=        {selectedTowerId ? 'OPtower' : 'OP'}
                    id=         {selectedTowerId ? selectedTowerId : selectedOPLid ? selectedOPLid : undefined}
                />
            </div>
        </div>
    )
}
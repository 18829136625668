import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type changeInfoCommand = {
    Id:          number | undefined,
    UserName:    string,
    FIO:         string,
    Description: string,
    Email:       string
};

export const changeInfo = (command: changeInfoCommand): Promise<EntityId<number>> => {
    return axios.put(`/users`, command);
};


export const useChangeInfo = () => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("userinfo");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка изменения информации"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("userinfo");
            showNotification({
                title: t("Успех!"),
                message: t("Информация успешно изменена"),
                autoClose: 5000,
                color: "teal",
            });
        },
        mutationFn: changeInfo,
    });
};
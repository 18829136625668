import { useMemo } from "react";
import { useDevices } from "src/features/devices/api/getDevices";
import { useDevicesStore } from "src/features/devices/store";
import { useDevice } from "../api/getDevice";

export const useSelectedDevice = () => {
    // const { data: devices } = useDevices();
    const selectedId = useDevicesStore((state) => state.selectedId);
    const { data: device } = useDevice(selectedId)
    // const selectedDevice = useMemo(() => devices?.find((x) => x.Id === selectedId), [selectedId, devices]);
    return device;
};

import { Button, Pagination, Flex, Stack, Group, Popover, ActionIcon } from "@mantine/core";
import { useState, useEffect } from "react";
import { IconTrash, IconEdit } from "@tabler/icons-react";

import { RoleEnum, UserStateEnum } from "src/features/auth";
import { useCompanyUsers } from "../api/getUsers";

import { UserT } from "../api/getUsers";
import { useTranslation } from "react-i18next";

import Fuse from "fuse.js";
import UserEditor from "./UserEditor";

import { useDeleteUser } from "../api/deleteUser";
import { t } from "msw/lib/glossary-de6278a9";

import { useEditUserCompany } from "../api/EditUserCompany";
import { useAuth } from "src/lib/auth";
import { PopoverDropdown } from "@mantine/core/lib/Popover/PopoverDropdown/PopoverDropdown";

type UserItemProps = {
    user: UserT;
    grayed: boolean;
    openEditor: () => void;
};

const UserItem: React.FC<UserItemProps> = ({ user, grayed, openEditor }) => {
    const [hovered, setHovered] = useState(false);
    const [color, setColor] = useState(grayed ? "#EFEFEF" : "#FFF");

    const { user: thisUser } = useAuth();

    const { t } = useTranslation();

    const [deleteOpened, setDeleteOpened] = useState(false);

    const deleteMutation = useDeleteUser(user.CompanyId);

    useEffect(() => {
        if (grayed && hovered) setColor("#EAEAEA");
        if (!grayed && hovered) setColor("#FAFAFA");
        if (grayed && !hovered) setColor("#EFEFEF");
        if (!grayed && !hovered) setColor("#FFFFFF");
    }, [hovered]);

    return (
        <div
            style={{
                userSelect: "none",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
                width: "100%",
                overflowX: "hidden",
                padding: "25px 25px",
                backgroundColor: color,
                cursor: "pointer",
                height: "fit-content",
                position: "relative",
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Stack
                style={{
                    width: "calc(100% - 120px)",
                    wordBreak: "break-all",
                }}
            >
                <div>{user.FIO ? user.FIO : user.Email}</div>
                {user.Description ? <div style={{ color: "gray" }}>{user.Description}</div> : ""}
            </Stack>
            {user.UserState === UserStateEnum.Invited ? (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        borderRadius: "0 0 8px 0",
                        backgroundColor: "black",
                        color: "#FFF",
                        fontSize: "11px",
                        padding: "3px",
                    }}
                >
                    {t("Приглашён")}
                </div>
            ) : null}
            {thisUser?.Id === user.Id ? (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        borderRadius: "0 0 8px 0",
                        backgroundColor: "black",
                        color: "#FFF",
                        fontSize: "11px",
                        padding: "3px",
                    }}
                >
                    {t("Это вы")}
                </div>
            ) : null}

            {thisUser?.Id !== user.Id && thisUser?.Role !== RoleEnum.User ? (
                <ActionIcon onClick={user ? openEditor : () => {}} size="lg" variant="default">
                    <IconEdit size={20} />
                </ActionIcon>
            ) : null}
            {thisUser?.Id !== user.Id && thisUser?.Role !== RoleEnum.User ? (
                <Popover
                    opened={deleteOpened}
                    onClose={() => setDeleteOpened(false)}
                    position="left-start"
                    transition="pop-top-right"
                >
                    <Popover.Target>
                        <ActionIcon
                            onClick={() => {
                                setDeleteOpened(true);
                            }}
                            size="lg"
                            variant="outline"
                            color="red"
                        >
                            <IconTrash size={20} />
                        </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <Button
                            variant={"subtle"}
                            color="red"
                            onClick={() => {
                                deleteMutation.mutateAsync({ Id: user.Id });
                            }}
                        >
                            {t("Удалить пользователя!").toString()}
                        </Button>
                    </Popover.Dropdown>
                </Popover>
            ) : null}
        </div>
    );
};

type UsersListProps = {
    company_id: number | null;
    filter: string;
    movingUser: UserT | null;
    setMovingUser: (user: UserT | null) => void;
};

const UsersList: React.FC<UsersListProps> = ({ company_id, filter, movingUser, setMovingUser }) => {
    const [activePage, setPage] = useState(1);

    const [editorOpened, setEditorOpened] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UserT | null>(null);

    const { t } = useTranslation();

    const editUserCompanyMutation = useEditUserCompany(company_id);

    const { data } = useCompanyUsers({
        company_id: company_id ? company_id : -1,
        request: {
            CompanyId: company_id ? company_id : -1,
            Skip: (activePage - 1) * 100,
            Take: 100,
        },
        config: {
            keepPreviousData: true,
        },
    });

    const fuse = new Fuse(data?.Page ?? [], {
        keys: ["FIO", "UserName", "Email"],
    });

    const start_editing = (user: UserT) => {
        setSelectedUser(user);
        setEditorOpened(true);
    };

    return (
        <Flex
            direction="column"
            bg="white"
            style={{
                overflow: "hidden",
                width: "100%",
                height: "100%",
                borderRadius: "8px 8px 0 0",
                justifyContent: "space-between",
            }}
            gap={9}
        >
            <div className="w-full overflow-y-auto">
                <div className="h-full">
                    {filter
                        ? fuse
                              .search(filter)
                              .map((user, index) => (
                                  <UserItem
                                      user={user.item}
                                      grayed={index % 2 === 0}
                                      openEditor={() => start_editing(user.item)}
                                  />
                              ))
                        : data?.Page.map((user, index) => (
                              <UserItem user={user} grayed={index % 2 === 0} openEditor={() => start_editing(user)} />
                          ))}
                    {movingUser && company_id ? (
                        <Group position="center" style={{ marginTop: "5px" }}>
                            <Button
                                onClick={() => {
                                    editUserCompanyMutation.mutateAsync({
                                        CompanyId: movingUser.CompanyId,
                                        UserId: movingUser.Id,
                                        NewCompanyId: company_id,
                                        // Role:           movingUser.Role,
                                    });
                                    setMovingUser(null);
                                }}
                            >
                                {t("Перенести пользователя сюда")}
                            </Button>
                            <Button onClick={() => setMovingUser(null)} variant="outline" color="red">
                                {t("Отменить перенос")}
                            </Button>
                        </Group>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <UserEditor
                opened={editorOpened}
                open={() => setEditorOpened(true)}
                close={() => setEditorOpened(false)}
                user={selectedUser}
                setMovingUser={setMovingUser}
            />
            <Pagination
                total={Math.floor((data?.Total || 0) / 100)}
                style={{ justifyContent: "center", marginBottom: "9px" }}
            />
        </Flex>
    );
};

export default UsersList;

import { useQuery } from "react-query";

import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { PagedCollection, PagedRequest } from "../../../types";
import { LanguageEnum } from "src/features/auth";
import { RoleEnum } from "src/features/auth";
import { UserStateEnum } from "src/features/auth";
import { useAuth } from "src/lib/auth";

export type UserT = {
    "Id": number,
    "UserName": "string",
    "Email": "string",
    "FIO": "string",
    "Description": "string",
    "UserState": UserStateEnum,
    "CompanyId": number,
    "Role": RoleEnum,
    "IEC": boolean,
    "IECPort": number,
    "Language": LanguageEnum
}

export interface GetCompanyUsersQuery extends PagedRequest {
    CompanyId: number;
}

export const getCompanyUsers = (request: GetCompanyUsersQuery): Promise<PagedCollection<UserT>> => {
    return axios.post(`Companies/${request.CompanyId}/users`, request);
};

type QueryFnType = typeof getCompanyUsers;

type UseCompanyUsersOptions = {
    company_id: number,
    request: GetCompanyUsersQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useCompanyUsers = ({ company_id, request, config }: UseCompanyUsersOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["users"+(company_id && company_id>-1 ? company_id : "root"), request],
        queryFn: () => getCompanyUsers(request),
    });
};

import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router";

export type recoverPasswordCommand = {
    "Email":     string,
};

export const recoverPassword = (command: recoverPasswordCommand): Promise<EntityId<number>> => {
    return axios.post(`/Users/recover-password`, command);
};


export const useRecoverPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("user");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка отправки заявки на восстановление пароля"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("user");
            navigate("/login")
            showNotification({
                title: t("Успех!"),
                message: t("Заявка на восстановление пароля успешно отправлена"),
                autoClose: 5000,
                color: "teal",
            });
        },
        mutationFn: recoverPassword,
    });
};
import React, { useEffect, useState } from "react";
import { ReportForm } from "../components/Report/ReportForm";
import { LoadingOverlay } from "@mantine/core";
import { useDevices } from "src/features/devices/api/getDevices";
import { DeviceDto } from "src/features/devices/types";
import { getDevice } from "src/features/devices/api/getDevice";

const ReportView: React.FC = () => {
    const { data: devices, isLoading } = useDevices();
    const [ selectedDevices, setSelectedDevices ] = useState<number[]>([])
    const [ devicesFull, setDevicesFull ] = useState<DeviceDto[]>()

    useEffect(()=>{
        if (devices && devices.length > 0){
            let tmp = Array.from(devices as unknown as DeviceDto[])
            Promise.all(tmp.map((elem, i)=>{
                if (selectedDevices.includes(tmp[i].Id))
                    return getDevice(tmp[i].Id)
                    .then(res=>{
                        tmp[i] = res as unknown as DeviceDto
                    })
            }))
            .then(res=>{setDevicesFull(tmp)})
        }
    }, [selectedDevices, devices])

    return (
        <>
            {/* {isMiddle ? <SideNav></SideNav> : <EnergyMobileNav></EnergyMobileNav>} */}
            {devicesFull && <div className="flex flex-1">{devicesFull.length > 0 && <ReportForm devices={devicesFull} selectedDevices={selectedDevices} selectDevices={setSelectedDevices} />}</div>}
            <LoadingOverlay visible={isLoading} />
        </>
    );
};

export default ReportView;

import { useTranslation } from "react-i18next";

import { Page } from "src/components/layout/Page";
import InfoForm from './InfoForm';

type InfoProps = {};

export const Info: React.FC<InfoProps> = () => {

    const { t } = useTranslation()

    return(
        <>
            <Page
                header={{
                    title: t("Настройки"),
                    description: t("Информация"),
                }}
            >
                <div
                    style={{
                        width: "50%",
                    }}
                >
                    <InfoForm/>
                </div>
            </Page>
        </>
    )
}
import React, { useEffect, useState } from "react";
import DeviceCard from "./DeviceCard";
import { IconSearch, IconChevronsLeft, IconChevronsRight } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import Fuse from "fuse.js";
import { Flex, Input, Group } from "@mantine/core";
import { useSelectedDevice } from "src/features/devices/hooks/useSelectedDevice";
import { useDevices } from "src/features/devices/api/getDevices";
import { useDevicesStore } from "src/features/devices/store";

import ListCollapser from "src/components/ListCollapser/ListCollapser";
import { useDevice } from "../api/getDevice";

interface DevicesListProps {}

export const DevicesList: React.FC<DevicesListProps> = ({}) => {
    const { data: devices } = useDevices();
    const { selectDevice } = useDevicesStore();
    const selectedDevice = useSelectedDevice();
    const { t } = useTranslation();
    const [filter, setFilter] = useState("");

    const fuse = new Fuse(devices ?? [], {
        keys: ["Name", "CCID", "Comment"],
    });

    const [newSelectedDeviceId, setNewSelectedDeviceId] = useState<number>()
    const { data: newSelectedDevice } = useDevice(newSelectedDeviceId)
    useEffect(()=>{
        if (newSelectedDevice)
            selectDevice(newSelectedDevice)
    }, [newSelectedDevice])

    return (
        <ListCollapser>
        <Group style={{gap: 0, width: "400px"}}>
            <Flex
                direction="column"
                bg="white"
                sx={(theme) => ({
                    height: "100%",
                    overflow: "hidden",
                    width: "100%",
                    zIndex: 10,
                })}
            >
                <div className="p-2 mb-2">
                    <div className="font-medium text-xl mb-4">{t("Мониторинг")}</div>
                    <Input
                        value={filter}
                        onChange={(e: any) => setFilter(e.target.value)}
                        icon={<IconSearch size={20} />}
                        placeholder={t("Поиск")}
                    />
                </div>

                <div className="w-full overflow-y-auto">
                    <div className="h-full">
                        {filter
                            ? fuse
                                .search(filter)
                                .map((results) => (
                                    <DeviceCard
                                        key={results.item.Id}
                                        isSelected={selectedDevice?.Id === results.item.Id}
                                        device={results.item}
                                        onClick={setNewSelectedDeviceId}
                                    />
                                ))
                            : devices?.map((device) => (
                                <DeviceCard
                                    key={device.Id}
                                    isSelected={selectedDevice?.Id === device.Id}
                                    device={device}
                                    onClick={setNewSelectedDeviceId}
                                />
                            ))}
                    </div>
                </div>
            </Flex>
        </Group>
        </ListCollapser>
    );
};

import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type deleteOPLCommand = {
    id: number;
};

export const deleteOPL = (command: deleteOPLCommand): Promise<EntityId<number>> => {
    return axios.delete(`/OverheadPowerLine/${command.id}`);
};

export const useDeleteOPL = () => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("OPLs");
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка удаления ВЛ"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("OPLs");
            showNotification({
                title: t("Успех!"),
                message: t("ВЛ успешно удалена"),
                autoClose: 5000,
                color: "teal",
            });
        },
        mutationFn: deleteOPL,
    });
};
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";

export type CompanyT = {
    Id:              number,
    Name:            string,
    CompanyParentId: number | null,
}

export const getCompany = (company_id: number | null): Promise<CompanyT> => {
    return axios.get(`/Companies/${company_id ? company_id : 0}`);
};

type QueryFnType = typeof getCompany;

export const useCompany = (company_id: number | null) => {
    const { t } = useTranslation();
    let result = useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ["company"+(company_id ? company_id : "root")],
        queryFn: () => getCompany(company_id),
        refetchInterval: 30000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
            showNotification({
                message: t("Ошибка при загрузке компаний"),
                title: t("Серверная ошибка"),
            });
        },
    });

    return result
};

import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";
import { useAuth } from "src/lib/auth";

export type registrationCommand = {
    Id:           number,
    UserName:     string,
    FIO:          string,
    Description:  string,
    Password:     string,
};

export const register = (command: registrationCommand): Promise<EntityId<number>> => {
    return axios.post(`/Users/registration`, command);
};


export const useRegistration = (company_id: number | null) => {
    const { t } = useTranslation();
    const { logout } = useAuth()
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("users"+(company_id && company_id>-1 ? company_id : "root"));
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка регистрации"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("users"+(company_id && company_id>-1 ? company_id : "root"));
            logout();
            showNotification({
                title: t("Успех!"),
                message: t("Регистрация прошла успешно"),
                autoClose: 5000,
                color: "teal",
            });
        },
        mutationFn: register,
    });
};
import React, { ReactNode } from "react";
import tw from "twin.macro";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { DeviceDto } from "src/features/devices/types";
import { Badge, Group } from "@mantine/core";
import { useDevice } from "../api/getDevice";

interface CardSelectIndicatorProps {
    $selected: boolean;
    $position: "right" | "left";
}

export const Card = styled.div((props) => ({
    ...tw`cursor-pointer border-b border-gray-200 bg-white transition-colors hover:bg-gray-100 relative`,
    minWidth: "250px",
}));

const CardSelectIndicator = styled.div<CardSelectIndicatorProps>(({ $selected, $position }) => ({
    ...tw`absolute transition`,
    top: "8px",
    bottom: "8px",
    [$position]: 0,
    width: "3px",
    borderRadius: $position === "left" ? "0 3px 3px 0" : "3px 0 0 3px",
    ...($selected ? tw`bg-black` : tw`bg-transparent`),
    transition: "all 250ms ease",
}));

interface DeviceCardProps {
    device: DeviceDto;
    onClick: (Id: number) => void;
    isSelected: boolean;
}

interface Status {
    title: string;
    css: string;
    icon: ReactNode;
}

interface Statuses {
    [key: number]: Status;
}

// const statuses: Statuses = {
//     [DeviceStatus.Warning]: {
//         title: "Warning",
//         css: "text-yellow-500 bg-yellow-50 rounded-full",
//         icon: <IconAlertCircle />,
//     },
//     [DeviceStatus.Emergency]: {
//         title: "Emergency",
//         css: "text-red-500 bg-red-50 rounded-full",
//         icon: <IconAlertCircle />,
//     },
//     [DeviceStatus.WrongData]: {
//         title: "Wrong data",
//         css: "text-red-500 bg-red-50 rounded-full",
//         icon: <IconAlertCircle />,
//     },
//     [DeviceStatus.Outdated]: {
//         title: "Outdated",
//         css: "text-gray-700 bg-gray-50 rounded-full",
//         icon: <IconClock />,
//     },
// };

const DeviceCard: React.FC<DeviceCardProps> = ({ device, onClick, isSelected }) => {
    const { t } = useTranslation();

    // function getStatusTagForDevice(device: IDeviceDto) {
    //     const { Status: deviceStatus } = device;
    //     if (deviceStatus === DeviceStatus.Normal || deviceStatus === DeviceStatus.Undefined) {
    //         return null;
    //     }
    //     return (
    //         <Tooltip placement="top" title={t(statuses[deviceStatus].title).toString()}>
    //             <div className={statuses[deviceStatus].css}>{statuses[deviceStatus].icon}</div>
    //         </Tooltip>
    //     );
    // }

    function onCardClick() {
        onClick(device.Id);
    }

    return (
        <Card style={{ minWidth: "275px" }} className="group py-2 pr-3 pl-4" onClick={onCardClick}>
            <CardSelectIndicator $selected={isSelected} $position="left" />
            <CardSelectIndicator $selected={isSelected} $position="right" />

            <div className="flex items-center justify-between font-medium text-sm mb-2">
                {device.Name}
                <Badge color="gray" radius="xs" variant="light">
                    {device.CCID}
                </Badge>
                {/* {getStatusTagForDevice(device)} */}
            </div>

            <div className="flex justify-between">
                <div className="font-normal text-sm text-gray-500">{device.Comment || "-"}</div>
            </div>
        </Card>
    );
};

export default DeviceCard;

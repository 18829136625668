import { Group, Paper, Stack, Title, Text, ActionIcon, SegmentedControl, Divider, Popover, Button } from "@mantine/core"
import { useTranslation } from "react-i18next";
import { RoleEnum } from "../../auth/types";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useAuth } from "src/lib/auth";
import { useState } from "react";

type MonitoringTowersHeadProps = {
    tab:                string,
    select_tab:         (x: string) => void,
    names_comments?:    {label?: string, name: string, comment: string}[],
    back_button?:       React.ReactNode,
    editing?:           {
                            state: boolean,
                            start: ()=>void,
                        },
    deleting?:          {
                            start: ()=>void,
                        },
    showTabs?:          boolean,
    tabs?:              any[]
}

export const MonitoringTowersHead: React.FC<MonitoringTowersHeadProps> = ({tab, select_tab, names_comments, back_button, editing, deleting, showTabs, tabs}) => {

    const { t } = useTranslation();

    const { user } = useAuth()

    const [ deleteOpened, setDeleteOpened ] = useState(false)

    return(
        <Paper className="mb-4" p="md" shadow="sm" style={{ boxSizing: "border-box" }}>
            <Stack>
                <Group>
                    {back_button ? back_button : ''}
                </Group>
                <Group position="apart" grow>
                    <Group>
                        {names_comments?.map((n_c, index)=><>
                            <Stack>
                                <Title order={2} style={{ wordBreak: "break-word" }}>
                                    {(n_c.label ? (n_c.label + ': '):'') + n_c.name}
                                </Title>
                                <Text color="gray" size="md">
                                    {n_c.comment}
                                </Text>
                            </Stack>
                            {index !== (names_comments.length - 1) ? 
                                <Divider size="sm" orientation="vertical" />
                            : ''}
                            </>
                        )}
                    </Group>
                    {user?.Role !== RoleEnum.User ? (
                        <Group style={{ maxWidth: "fit-content" }}>
                            {editing ?
                                <ActionIcon 
                                    size="lg" 
                                    variant="default"
                                    onClick={editing?.start}
                                >
                                    <IconEdit size={20} />
                                </ActionIcon>
                            :''}
                            {deleting ? 
                                <Popover
                                    opened={deleteOpened}
                                    onClose={() => setDeleteOpened(false)}
                                    position="left-start"
                                    transition="pop-top-right"
                                >
                                    <Popover.Target>
                                        <ActionIcon
                                            onClick={() => {setDeleteOpened(true)}}
                                            size="lg"
                                            variant="outline"
                                            color="red"
                                        >
                                            <IconTrash size={20} />
                                        </ActionIcon>
                                    </Popover.Target>
                                    <Popover.Dropdown>
                                        <Button
                                            variant={"subtle"}
                                            color="red"
                                            onClick={() => {
                                                setDeleteOpened(false);
                                                deleting.start();
                                            }}
                                        >
                                            {t("Удалить!").toString()}
                                        </Button>
                                    </Popover.Dropdown>
                                </Popover>
                            :''}
                        </Group>
                    ) : (
                        ""
                    )}
                </Group>
                
                {showTabs ?
                <SegmentedControl
                    data={tabs?tabs:[]}
                    styles={{
                        label: {
                            width: "fit-content",
                        },
                        control: {
                            width: "fit-content",
                        },
                        root: {
                            width: "fit-content",
                        },
                    }}
                    value={tab}
                    onChange={select_tab}
                />
                :''}
            </Stack>
        </Paper>
    )
}
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type deleteGroupCommand = {
    id: number;
};

export const deleteGroup = (command: deleteGroupCommand): Promise<EntityId<number>> => {
    return axios.delete(`/Companies/` + command.id);
};

export const useDeleteGroup = (id: number) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries("companies"+(id && id>0 ? id : "root"));
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка удаления группы"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("companies"+(id && id>0 ? id : "root"));
            showNotification({
                title: t("Успех!"),
                message: t("Группа успешно удалена"),
                autoClose: 5000,
                color: "teal",
            });
        },
        mutationFn: deleteGroup,
    });
};
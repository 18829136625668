import React, { useRef, useEffect, useState } from "react";
import { useYMaps } from "@pbe/react-yandex-maps";
import YandexMap from "../../components/Map/YandexMap";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { Tower_small } from "src/features/OPL/types";
import { useTranslation } from "react-i18next";
import { Badge, Box, Group, Paper } from "@mantine/core";
import ymaps from "yandex-maps";
import ReactDOMServer from "react-dom/server";
import { useTowersStore } from "src/features/OPL/store";
import { useNavigate } from "react-router";

type objectDto = {
    Name: string,
    Comment: String,
    Id: number,
    Latitude: number | null,
    Longitude: number | null,
}

type MarkerProps = {
    object: objectDto;
};

export const Marker: React.FC<MarkerProps> = ({ object }) => {
    const { t } = useTranslation();

    return (
        <Group spacing="xs" style={{position: "relative"}}>
            <div style={{
                position: "absolute",
                top: "10px",
                left: "10px",
            }}>
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.colors.dark,
                    height: 12,
                    width: 12,
                    color: "#fff",
                    opacity: 1,
                    zIndex: 2,
                    borderRadius: "50%",
                    border: "1px solid #fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                })}
            ></Box>
            </div>
            <Group
                px={4}
                style={{
                    position: "absolute",
                    left: "20px"
                }}
            >
                <Badge radius="xs" variant="filled" color="dark">
                    {object.Name.slice(0, 10)}
                </Badge>
            </Group>
        </Group>
    );
};

Marker.displayName = "Marker";

class PositionMarker {
    private map: YandexMap;
    private object: objectDto;
    private layer: ymaps.Placemark;
    private ymaps: typeof ymaps;

    constructor(map: YandexMap, object: objectDto, clickHandler: any) {
        this.ymaps = map.ymapsAPI;
        this.map = map;
        this.object = { ...object };
        this.layer = this.createPlacemark(this.object);
        this.layer.events.add("click", (event) => {
            if (clickHandler) {
                const target = (event as ymaps.IEvent).get("target");
                if (target) {
                    const placemark = target as ymaps.Placemark;
                    clickHandler(placemark.properties.getAll());
                }
            }
        });
    }

    public getBounds() {
        return this.layer.geometry?.getBounds();
    }

    public focus() {
        const center = this.layer.geometry?.getCoordinates();
        this.map.setCenter(center);
    }

    public show() {
        if (!this.map.isLayerOnMap(this.layer)) {
            this.map.addLayerToClusterer(this.layer);
        }
    }

    public hide() {
        this.map.removeLayerFromClusterer(this.layer);
    }

    private createLayout(template: any) {
        return this.map.ymapsAPI.templateLayoutFactory.createClass(template);
    }

    public startEditing(updateGeometryCallback: any) {
        this.layer.editor.startEditing();
        updateGeometryCallback();
        if (this.layer.geometry) {
            this.layer.geometry.events.add("change", updateGeometryCallback);
        }
    }

    public stopEditing(updateGeometryCallback: any) {
        this.layer.editor.stopEditing();
        if (this.layer.geometry) {
            this.layer.geometry.events.remove("change", updateGeometryCallback);
        }
    }

    private createPlacemark(object: objectDto) {
        let placemarkColor = "#1070ca";
        const properties = {
            Id: object.Id,
            Name: object.Name,
            needLabel: true,
            IconSize: 24,
            Color: placemarkColor,
            clusterCaption: object.Name,
            balloonContentBody: ReactDOMServer.renderToStaticMarkup(<div>{object.Comment || "-"}</div>),
        };

        const options: ymaps.IPlacemarkOptions = {
            hasBalloon: false,
            iconLayout: "default#imageWithContent",
            iconContentLayout: this.createLayout(ReactDOMServer.renderToStaticMarkup(<Marker object={object} />)),
            iconColor: placemarkColor,
            iconContentSize: [280, 32],
            iconImageHref: "",
            iconImageSize: [32, 32],
            iconImageOffset: [-32 / 2, -32 / 2],
        };

        return new this.ymaps.Placemark([object.Latitude, object.Longitude], properties, options);
    }
}

const MapContainer = styled.div({
    ...tw`h-full absolute left-0 right-0 flex-1`,
});

interface TowersMapProps {
    towers?: Tower_small[];
}

let towersMarkers: PositionMarker[] = [];

const TowersMap: React.FC<TowersMapProps> = ({ towers }) => {
    const ymap = useRef<YandexMap>();
    const ymaps = useYMaps();
    const { selectTower } = useTowersStore()
    const navigate = useNavigate()

    const [ userPosition, setUserPosition ] = useState<number[]>([])    

    const get_user_position = () => {
        navigator.geolocation.getCurrentPosition(position => setUserPosition([position.coords.latitude, position.coords.longitude]))
    }

    useEffect(()=>{
        console.log('effect')
        get_user_position()
    }, [navigator])

    useEffect(() => {
        if (ymaps && ymaps.Map) {
            ymap.current = new YandexMap(ymaps, "ymap", {
                center: userPosition.length !== 0 ? userPosition : [54.194442, 37.599728],
                zoom: 10,
                maxZoom: 18,
            });
        }

        return () => {
            if (ymap.current) {
                ymap.current.destroy();
            }
        };
    }, [ymaps]);

    type LinesStructure = {
        [OPL_id: number]: [{
            Tower_id:   number,
            Latitude:   number | null,
            Longitute:  number | null,
        }]
    }

    useEffect(() => {
        towersMarkers.forEach((x) => x.hide());
        const markers: PositionMarker[] = [];

        const lines: LinesStructure = {}

        towers?.forEach((x) => {
            if (ymap.current && x.Latitude && x.Longitude) {
                const marker = new PositionMarker(ymap.current, x, () => {
                    selectTower(x);
                    navigate("/monitoring")
                });
                marker.show();
                markers.push(marker);
            }
        });
        towersMarkers = markers;

        towers?.forEach((x)=>{
            if (x.OverheadPowerLineId && lines[x.OverheadPowerLineId])
                lines[x.OverheadPowerLineId].push({
                    Tower_id:   x.TowerId,
                    Latitude:   x.Latitude,
                    Longitute:  x.Longitude,
                })
            else if (x.OverheadPowerLineId && (! lines[x.OverheadPowerLineId]))
                lines[x.OverheadPowerLineId] = [{
                    Tower_id:   x.TowerId,
                    Latitude:   x.Latitude,
                    Longitute:  x.Longitude,
                }]
        })

        for (let OPL_id in lines){
            lines[OPL_id] = lines[OPL_id].sort((a, b) => a.Tower_id - b.Tower_id)
            let coordinates = []
            for (let Tower of lines[OPL_id]){
                coordinates.push([Tower.Latitude, Tower.Longitute])
            }
            if (ymaps && ymaps.Map)
                ymap.current?.addLayer(new ymaps.Polyline(coordinates, {
                    // Описываем свойства геообъекта.
                    // Содержимое балуна.
                    balloonContent: "Ломаная линия"
                }, {
                    // Задаем опции геообъекта.
                    // Цвет линии.
                    strokeColor: "#000000",
                    // Ширина линии.
                    strokeWidth: 4,
                    // Коэффициент прозрачности.
                    strokeOpacity: 0.5
                }))
        }

        return () => {
            towersMarkers.forEach((x) => x.hide());
        };
    }, [towers, ymaps, selectTower]);

    return <MapContainer id="ymap" />;
};

export default TowersMap;

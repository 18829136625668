import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SchemaOf, object, string, number, date, boolean } from "yup";
import { Box, Button, Group, NumberInput, Select, Switch, Textarea, TextInput } from "@mantine/core";
import { CreateDeviceCommand, useCreateDevice } from "src/features/devices/api/createDevice";
import SelectPositionMap from "src/components/Monitoring/SelectPositionMap";
import { useForm, yupResolver } from "@mantine/form";
import { debounce } from "lodash";
import { useUsers } from "src/features/users/api/getUsers";
import { useBindDevice } from "../api/bindDevice";
import { useAuth } from "../../../lib/auth";
import { RoleEnum } from "../../auth";
import { DatePicker } from "@mantine/dates";

const CreateDeviceCommandSchema: SchemaOf<CreateDeviceCommand> = object({
    Name: string().required("Имя не может быть пустым"),
    CCID: string().required("CCID не может быть пустым"),
    Comment: string().required().min(0),
    Latitude: number().nullable(),
    Longitude: number().nullable(),
    DeviceModelId: number().required(),
    AutoTimeZoneDetection: boolean().required(),
    AutoTimeZoneSeasonChanging: boolean().required(),
    SummerTimeZoneChanging: date().nullable(),
    WinterTimeZoneChanging: date().nullable(),
    TimeZone: number().nullable().required(),
});

interface CreateDeviceDrawerProps {
    onComplete: () => void;
}

export const CreateDeviceDrawer: React.FC<CreateDeviceDrawerProps> = ({ onComplete }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const createDeviceMutation = useCreateDevice();
    const bindDeviceMutation = useBindDevice();
    const { data: users } = useUsers();
    const [userId, setUserId] = useState<number>(users ? users[0].Id : -1);

    const [coordinatesLocal, setCoordinatesLocal] = useState<[number | null | undefined, number | null | undefined]>([
        null,
        null,
    ]);

    const form = useForm<CreateDeviceCommand>({
        initialValues: {
            Name: "",
            CCID: "",
            Comment: "",
            DeviceModelId: 1,
            Latitude: null,
            Longitude: null,
            AutoTimeZoneDetection: true,
            AutoTimeZoneSeasonChanging: false,
            SummerTimeZoneChanging: null,
            WinterTimeZoneChanging: null,
            TimeZone: 0,
        },
        validate: yupResolver(CreateDeviceCommandSchema),
    });

    const usersData = users?.map((x) => ({ label: x.UserName, value: String(x.Id) }));

    function handleClose() {
        onComplete();
        form.reset();
    }

    return (
        <form
            className="flex flex-col"
            onSubmit={form.onSubmit(async (values) => {
                const entityId = await createDeviceMutation.mutateAsync({
                    Name: values.Name,
                    CCID: values.CCID,
                    Comment: values.Comment,
                    DeviceModelId: values.DeviceModelId,
                    Latitude: values.Latitude,
                    Longitude: values.Longitude,
                    AutoTimeZoneDetection: values.AutoTimeZoneDetection,
                    AutoTimeZoneSeasonChanging: values.AutoTimeZoneSeasonChanging,
                    SummerTimeZoneChanging: values.SummerTimeZoneChanging,
                    WinterTimeZoneChanging: values.WinterTimeZoneChanging,
                    TimeZone: values.TimeZone,
                });

                if (user?.Role !== RoleEnum.User && entityId.Id && userId) {
                    await bindDeviceMutation.mutateAsync({ UserId: userId, DeviceId: entityId.Id });
                }

                handleClose();
            })}
        >
            <div className="space-y-4 flex-auto">
                <TextInput
                    id="Name"
                    name="Name"
                    placeholder={t("Название")}
                    label={t("Название")}
                    {...form.getInputProps("Name")}
                />
                <TextInput
                    id="CCID"
                    name="CCID"
                    placeholder={t("CCID")}
                    label={t("CCID")}
                    {...form.getInputProps("CCID")}
                    required
                />
                <Textarea
                    id="Comment"
                    name="Comment"
                    placeholder={t("Комментарий")}
                    label={t("Комментарий")}
                    {...form.getInputProps("Comment")}
                />
                {user?.Role !== RoleEnum.User && (
                    <Select
                        data={usersData || []}
                        label={t("Привязка к пользователю")}
                        searchable
                        nothingFound="No options"
                        value={String(userId)}
                        onChange={(value) => setUserId(Number(value))}
                    />
                )}
                <Select
                    value={form.values.DeviceModelId.toString()}
                    onChange={(value) => {
                        form.setFieldValue("DeviceModelId", Number(value));
                    }}
                    label={t("Тип устройства")}
                    data={[
                        { value: "1", label: t("МДД") },
                        { value: "2", label: t("КЗ") },
                        { value: "3", label: t("Универсальное") },
                        { value: "4", label: t("Универсальное 2.1") },
                        { value: "5", label: t("Универсальное 2.2") },
                    ]}
                />
                <Box
                    sx={{
                        height: "300px",
                    }}
                >
                    <SelectPositionMap
                        device={{
                            Id: 0,
                            CCID: form.values.CCID,
                            Comment: form.values.Comment,
                            Name: form.values.Name,
                            DeviceModel: form.values.DeviceModelId,
                            Latitude: coordinatesLocal[0],
                            Longitude: coordinatesLocal[1],
                            DeviceParameters: [],
                            DeviceChannels: [],
                            IceV2CalculationEnabled: false,
                            AutoTimeZoneDetection: form.values.AutoTimeZoneDetection,
                            AutoTimeZoneSeasonChanging: form.values.AutoTimeZoneSeasonChanging,
                            SummerTimeZoneChanging: form.values.SummerTimeZoneChanging,
                            WinterTimeZoneChanging: form.values.WinterTimeZoneChanging,
                            TimeZone: form.values.TimeZone,
                            GetOscilloscope: false,
                            TimeSynchronization: false,
                        }}
                        onChange={debounce((value) => {
                            form.setFieldValue("Latitude", value[0]);
                            form.setFieldValue("Longitude", value[1]);
                        }, 300)}
                        onClick={(value) => {
                            setCoordinatesLocal(value);
                        }}
                    />
                </Box>
                <Group>
                    <NumberInput
                        placeholder={t("Широта")}
                        label={t("Широта")}
                        precision={4}
                        hideControls
                        {...form.getInputProps("Latitude")}
                    />
                    <NumberInput
                        placeholder={t("Долгота")}
                        label={t("Долгота")}
                        precision={4}
                        hideControls
                        {...form.getInputProps("Longitude")}
                    />
                </Group>
                <Switch
                    label={t("Автоматическое определение часового пояса")}
                    {...form.getInputProps("AutoTimeZoneDetection", { type: "checkbox" })}
                />
                {!form.values.AutoTimeZoneDetection && (
                    <>
                        <NumberInput
                            label={t("Часовой пояс")}
                            step={1}
                            min={-11}
                            max={12}
                            {...form.getInputProps("TimeZone")}
                        />
                    </>
                )}
                <Switch
                    label={t("Изменение часового времени на летнее")}
                    {...form.getInputProps("AutoTimeZoneSeasonChanging", { type: "checkbox" })}
                />
                {form.values.AutoTimeZoneSeasonChanging && (
                    <>
                        <DatePicker
                            label={t("Дата перехода на летнее время")}
                            {...form.getInputProps("SummerTimeZoneChanging")}
                        ></DatePicker>
                        <DatePicker
                            label={t("Дата перехода на зимнее время")}
                            {...form.getInputProps("WinterTimeZoneChanging")}
                        ></DatePicker>
                    </>
                )}
            </div>
            <div className="space-x-3 mt-8 flex justify-end">
                <Button color="primary" variant="outline" onClick={handleClose}>
                    {t("Отмена")}
                </Button>
                <Button color="primary" type="submit">
                    {t("Создать")}
                </Button>
            </div>
        </form>
    );
};

import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionIcon, Group, Paper, Tooltip } from "@mantine/core";
import { useSelectedDevice } from "src/features/devices/hooks/useSelectedDevice";
import { useDeviceFirmwares } from "../api/getDeviceFirmwares";
import { FirmwareDto, statusLabels } from "src/features/firmware/types";
import { DataTable } from "mantine-datatable";
import dayjs from "dayjs";
import { useAllFirmwares } from "src/features/firmware/api/getAllFirmwares";
import { IconTrash } from "@tabler/icons-react";
import { usePauseFirmware } from "src/features/firmware/api/pauseFirmware";
import { RoleEnum } from "src/features/auth";
import { useAuth } from "src/lib/auth";


type FirmwareProps = {
    deviceId: number;
};

export const Firmware: React.FC<FirmwareProps> = ({ deviceId }) => {
    const selectedDevice = useSelectedDevice();
    const { t } = useTranslation();
    const { data: firmwares } = useAllFirmwares();
    const { mutateAsync } = usePauseFirmware();

    const { user, logout } = useAuth();

    const firmwaresDict = useMemo(
        () => firmwares?.reduce<Record<number, FirmwareDto>>((acc, value) => ({ ...acc, [value.Id]: value }), {}),
        [firmwares]
    );

    const [activePage, setPage] = useState(1);
    const { data } = useDeviceFirmwares({
        request: {
            DeviceId: deviceId,
            Skip: (activePage - 1) * 100,
            Take: 100,
        },
        config: {
            keepPreviousData: true,
        },
    });

    if (!selectedDevice) {
        return null;
    }
    return (
        <Paper className="mb-4" p="md" shadow="sm">
            {firmwaresDict && (
                <DataTable
                    minHeight={150}
                    withBorder
                    borderRadius="sm"
                    withColumnBorders
                    striped
                    highlightOnHover
                    // provide data
                    records={data?.Page}
                    // define columns
                    columns={[
                        {
                            accessor: "FirmwareId",
                            title: t("Прошивка"),
                            textAlignment: "center",
                            render: ({ FirmwareId }) => firmwaresDict[FirmwareId].Name,
                        },
                        {
                            accessor: "Status",
                            title: t("Статус прошивки"),
                            textAlignment: "center",
                            render: ({ Status }) => (t(statusLabels[Status])),
                        },
                        {
                            accessor: "PageNumber",
                            title: t("Страницы"),
                            textAlignment: "center",
                            render: ({ PageNumber, Pages }) => (Pages ? `${PageNumber} / ${Pages}` : PageNumber),
                        },
                        {
                            accessor: "StartDate",
                            title: t("Начало"),
                            textAlignment: "center",
                            render: ({ StartDate }) => (dayjs(StartDate).format("DD.MM.YY HH:mm")),
                        },
                        {
                            accessor: "Status",
                            title: t("Окончание"),
                            textAlignment: "center",
                            render: ({ EndDate }) => (EndDate ? dayjs(EndDate).format("DD.MM.YY HH:mm") : EndDate),
                        },
                        {
                            accessor: "actions",
                            title: t("Действия"),
                            textAlignment: "right",
                            render: ({ Status, Id, Pause }) => (
                                ((Status != 5) && (Status != 6) && (Status != 7) && (Status != 8) && (!Pause) && (user?.Role !== RoleEnum.User)) ?
                                    <Group spacing={4} position="right" noWrap>
                                        <Tooltip label={t("Остановить прошивку")}>
                                            <ActionIcon
                                                color="black"
                                                onClick={() =>
                                                    mutateAsync({ Id: Id })
                                                }
                                            >
                                                <IconTrash size={20} />
                                            </ActionIcon>
                                        </Tooltip>
                                    </Group> : null
                            ),
                        },
                    ]}
                />
            )}
        </Paper>
    );
};

Firmware.displayName = "Firmware";
